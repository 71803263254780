<template>
  <div class="compressors-edit-all">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
            <Pageheader :title="`Edit All Compressors`" />
            <Form @submit="update" autocomplete="off">
                <fieldset :disabled="busy">
                    <MDBSpinner v-if="busy" />
                    <MDBTable style="width: 100%;" align="middle" responsive sm  variant="light" class="mt-3 shadow" v-if="$store.state.user.roles.indexOf('administrator')!=-1">
                        <thead>
                            <tr>
                                <th width="20"></th>
                                <th>Compressor</th>
                                <th>500 or Less</th>
                                <th>4000</th>
                                <th>6000</th>
                                <th>8000</th>
                                <th>Control Parts 2x ACP</th>
                                <th>Control Parts 3x ACP</th>
                                <th>Control Parts 4x ACP</th>
                                <th>Sump</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(compressor,i) in compressors" :key="compressor.ID">
                                <td  @click="removeRow(i)" class="p-1 text-center bg-danger text-white"><MDBIcon icon="minus-circle" iconStyle="fas" /></td>
                                <td class="p-1"><Field type="text" class="form-control rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][post_title]`" v-model="compressor.post_title"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][500_or_less]`" v-model="compressor.acf['500_or_less']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][4000]`" v-model="compressor.acf['4000']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][6000]`" v-model="compressor.acf['6000']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][8000]`" v-model="compressor.acf['8000']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][control_parts_2x_acp]`" v-model="compressor.acf['control_parts_2x_acp']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][control_parts_3x_acp]`" v-model="compressor.acf['control_parts_3x_acp']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][control_parts_4x_acp]`" v-model="compressor.acf['control_parts_4x_acp']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`compressor[${i}][acf][sump]`" v-model="compressor.acf['sump']"  /></td>
                            </tr>
                            <!-- <tr v-for="(region,i) in regions" :key="region.ID">
                                <td  @click="removeRow(i)" class="p-1 text-center bg-danger text-white"><MDBIcon icon="minus-circle" iconStyle="fas" /></td>
                                <td class="p-1"><Field type="text" class="form-control rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][region]`" v-model="region.acf['region']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][2_visits]`" v-model="region.acf['2_visits']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][3_visits]`" v-model="region.acf['3_visits']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][4_visits]`" v-model="region.acf['4_visits']"  /></td>
                            </tr> -->
                        </tbody>
                    </MDBTable>
                    <Pagination :pagination="pagination" />
                    <PageUpdateButtons />
                </fieldset>
                
            </Form>
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import * as yup from 'yup';
export default {
  name: 'RegionsEditAll',
  components: {
  },
  data(){
    return {
      compressors: [],
      allCompressors:[],
      ready:false,
      busy:false,
      pagination:{
          count:0,
          page:1,
          per_page:10,
          pages:1,
          searchText:''
      },
      schema:yup.object().shape({
        '2_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '3_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '4_visits':yup.string().matches(/\d+/).required('Must be a valid number')
      }),
    }
  },
  async mounted(){
    this.allCompressors =  (await this.$store.state.db.compressors.orderBy('post_title').toArray());
    this.allCompressors = _.map(this.allCompressors, compressor=>{
        if(typeof compressor.acf === 'string') compressor.acf = JSON.parse(compressor.acf);
        return compressor; 
    });
    this.allCompressors = (_.chunk(this.allCompressors,10));
    this.pagination.pages = this.allCompressors.length;
    this.compressors = this.allCompressors[this.pagination.page-1];
    this.ready = true;
  },
  methods:{
    async removeRow(index){
      console.log(index)
    },
    async update(){
      this.busy = true;
    //   let req = await fetch(`${this.$store.state.api}/regions/${this.region.ID}?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.region.acf), p=>p.join('=')).join('&'))))}`, {
    //     method:'PUT',
    //     body:JSON.stringify(this.region.acf),
    //     headers:{
    //         'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
    //     }
    //   });
    //   let res = await req.json();
    //   if(res.hasOwnProperty('error')){
    //     this.$router.replace({query:{danger:btoa(res.error)}});
    //   }
    //   if(res.hasOwnProperty('success')){
    //     this.$router.replace({query:{success:btoa(res.success)}});
    //     await this.$store.state.db.regions.where('id').equals(this.region.ID).modify({acf:JSON.stringify(this.region.acf)});
    //   }
      await Sleep(2000);
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>