<template>
  <div class="dryers-create">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="`Create Dryer`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Dryer Type</label>
                    <Field as="select"  class="form-control" name="dryerType" v-model="dryerType">
                      <option value="" disabled> -- Dryer Type -- </option>
                      <option value="Refridgerated">Refridgerated</option>
                      <option value="Heatless">Heatless</option>
                      <option value="Heated">Heated</option>
                      <option value="Blower">Blower</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="dryerType" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Dryer</label>
                    <Field  class="form-control" name="dryerDescription" v-model="dryerDescription"   />
                    <!-- <Field  class="form-control" name="post_title" v-model="dryer['post_title']"   /> -->
                    <ErrorMessage class="text-danger" name="dryerDescription" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">2 Visits</label>
                    <Field  type="number" class="form-control" name="4000" v-model="dryer.acf['4000']"  />
                    <ErrorMessage class="text-danger" name="4000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">3 Visits</label>
                    <Field type="number" class="form-control" name="6000" v-model="dryer.acf['6000']"  />
                    <ErrorMessage class="text-danger" name="6000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">4 Visits</label>
                    <Field type="number" class="form-control" name="8000" v-model="dryer.acf['8000']"  />
                    <ErrorMessage class="text-danger" name="8000" />
                </div>
                <PageCreateButtons :errors="errors" />
              </fieldset>
              
            </Form>
            
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
import {
    MDBListGroup, MDBListGroupItem,
    MDBProgress,
    MDBProgressBar,
    MDBContainer,
    MDBBtn,
    MDBSpinner
} from "mdb-vue-ui-kit";
export default {
  name: 'dryersEdit',
  components: {
    MDBListGroup,
    MDBListGroupItem,
    MDBProgress,
    MDBProgressBar,
    MDBContainer,
    MDBBtn,
    MDBSpinner,
    Field,
    Form,
    ErrorMessage,
    defineRule
  },
  data(){
    return {
      dryer: _.merge(..._.map(this.$store.state.db.dryers.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      dryerType:'',
      dryerDescription:'',
      schema:yup.object().shape({
        'dryerType':yup.string().required('Field is required').label('Dryer Type'),
        'dryerDescription':yup.string().required('Field is required').label('Dryer Description'),
        // 'post_title':yup.string().required('Field is required').label('Dryer'),
        '4000':yup.string().matches(/\d+/).required('Must be a valid number'),
        '6000':yup.string().matches(/\d+/).required('Must be a valid number'),
        '8000':yup.string().matches(/\d+/).required('Must be a valid number'),
      })
    }
  },
  watch:{
    dryerType(newVal){
      let post_title = this.dryer.post_title.split(' - ');
      this.dryer.post_title = `${newVal} - ${post_title[1]}`;
    },
    dryerDescription(newVal){
      let post_title = this.dryer.post_title.split(' - ');
      this.dryer.post_title = `${post_title[0]} - ${newVal}`;
    },
  },
  async mounted(){
    this.dryer.acf = {'4000':'', '6000':'','8000':''};
    this.dryer.post_title = ' - ';
    this.ready = true;
  },
  methods:{
    async create(){
      this.busy = true;
      this.dryer.post_author = `${parseInt(this.$store.state.user.ID)}`;
      this.dryer.post_status = `publish`;
      this.dryer.post_type = `dryers`;
      let req = await fetch(`${this.$store.state.api}/dryers/create?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.dryer.acf), p=>p.join('=')).join('&'))))}`, {
        method:'POST',
        body:JSON.stringify(this.dryer),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.push({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        // this.$router.push({query:{success:btoa(res.success)}});
        await this.$store.state.db.dryers.add(res.dryer);
        this.$router.replace(`/dryers/${res.dryer.ID}/edit?success=${btoa(res.success)}`);
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>
