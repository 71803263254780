<template>
  <div class="regions-create">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="`Create Region`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Region</label>
                    <Field  class="form-control" name="region" v-model="region.acf['region']" readonly />
                    <ErrorMessage class="text-danger" name="region" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">2 Visits</label>
                    <Field  type="number" class="form-control" name="2_visits" v-model="region.acf['2_visits']"  />
                    <ErrorMessage class="text-danger" name="2_visits" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">3 Visits</label>
                    <Field type="number" class="form-control" name="3_visits" v-model="region.acf['3_visits']"  />
                    <ErrorMessage class="text-danger" name="3_visits" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">4 Visits</label>
                    <Field type="number" class="form-control" name="4_visits" v-model="region.acf['4_visits']"  />
                    <ErrorMessage class="text-danger" name="4_visits" />
                </div>
                <hr>
                <div class="form-group mt-2 mb-2">
                  <label for="">Mile Range</label>
                  <MDBRow>
                    <MDBCol>
                      <label for="">Min</label>
                      <Field type="number" class="form-control" :name="`min`" v-model="region.acf['range']['min']"  />
                      <ErrorMessage class="text-danger" :name="`min`" />
                    </MDBCol>
                    <MDBCol>
                      <label for="">Min</label>
                      <Field type="number" class="form-control" :name="`max`" v-model="region.acf['range']['max']"  />
                      <ErrorMessage class="text-danger" :name="`max`" />
                    </MDBCol>
                  </MDBRow>
                </div>
                <PageCreateButtons :errors="errors" />
              </fieldset>
              
            </Form>
            
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import * as yup from 'yup';
export default {
  name: 'RegionsEdit',
  components: {
  },
  watch:{
    ['region.acf.range']:{
      deep:true,
      handler(newVal){
        this.region.acf.region = `(${newVal.min}-${newVal.max} mi o/w)`;    
      }
    }
  },
  data(){
    return {
      region: _.merge(..._.map(this.$store.state.db.regions.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
        'region':yup.string().required('Field is required').label('Region'),
        '2_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '3_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '4_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        min:yup.string().matches(/\d+/).required('Must be a valid number'),
        max:yup.string().matches(/\d+/).required('Must be a valid number')
      })
    }
  },
  async mounted(){
    this.region.acf = {'region':'','2_visits':0,'3_visits':0,'4_visits':0,range:{min:0,max:0}};
    this.ready = true;
  },
  methods:{
    async create(){
      // this.schema.validate(this.region);
      this.busy = true;
      this.region.post_title = '';
      this.region.post_author = `${parseInt(this.$store.state.user.ID)}`;
      this.region.post_status = `publish`;
      this.region.post_type = `regions`;
      
      let req = await fetch(`${this.$store.state.api}/regions/create?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.region.acf), p=>p.join('=')).join('&'))))}`, {
        method:'POST',
        body:JSON.stringify(this.region),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        await this.$store.state.db.regions.add(res.region);
        this.$router.replace(`/regions/${res.region.ID}?success=${btoa(res.success)}`);
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>