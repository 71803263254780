<template>
  <div class="customers-edit">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
            <Pageheader :title="`Create Customer`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Customer</label>
                    <Field  class="form-control" name="post_title" v-model="customer['post_title']"   />
                    <ErrorMessage class="text-danger" name="post_title" />
                </div>
                <h5>Bill-To</h5>
                <div class="row">
                  <div class="form-group mt-2 mb-2">
                    <label for="">Address</label>
                    <Field  class="form-control" name="bill_to_address" v-model="customer.acf['locations']['bill_to'].address"   />
                    <ErrorMessage class="text-danger" name="bill_to_address" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12 col-lg-4">
                      <label for="">City</label>
                      <Field  class="form-control" name="bill_to_city" v-model="customer.acf['locations']['bill_to'].city"   />
                      <ErrorMessage class="text-danger" name="bill_to_city" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12 col-lg-4">
                      <label for="">State</label>
                      <Field as="select"  class="form-control" name="bill_to_state" v-model="customer.acf['locations']['bill_to'].state">
                        <option value="AL">Alabama</option><option value="AK">Alaska</option><option value="AZ">Arizona</option><option value="AR">Arkansas</option><option value="CA">California</option><option value="CO">Colorado</option><option value="CT">Connecticut</option><option value="DE">Delaware</option><option value="DC">District Of Columbia</option><option value="FL">Florida</option><option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option><option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option><option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option><option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option><option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option><option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option><option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option><option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option><option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option><option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option><option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option><option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option><option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option><option value="WI">Wisconsin</option><option value="WY">Wyoming</option>
                      </Field>
                      <ErrorMessage class="text-danger" name="bill_to_state" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12 col-lg-4">
                      <label for="">ZIP</label>
                      <Field  class="form-control" name="bill_to_zip" v-model="customer.acf['locations']['bill_to'].zip"   />
                      <ErrorMessage class="text-danger" name="bill_to_zip" />
                  </div>
                </div>
                <label for="shipToBillingToSame"><input type="checkbox" id="shipToBillingToSame" v-model="shipToBillingToSame"> Use bill to address for ship to address</label>
                <hr>
                <h5>Ship-To</h5>
                <div class="row">
                  <div class="form-group mt-2 mb-2 col-12">
                    <label for="">Address</label>
                      <Field  class="form-control" name="ship_to_address" v-model="customer.acf['locations']['ship_to'].address"  :disabled="shipToBillingToSame" />
                      <ErrorMessage class="text-danger" name="ship_to_address" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12 col-lg-4">
                      <label for="">City</label>
                      <Field  class="form-control" name="ship_to_city" v-model="customer.acf['locations']['ship_to'].city" :disabled="shipToBillingToSame"  />
                      <ErrorMessage class="text-danger" name="ship_to_city" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12 col-lg-4">
                      <label for="">State</label>
                      <Field as="select"  class="form-control" name="ship_to_state" v-model="customer.acf['locations']['ship_to'].state" :disabled="shipToBillingToSame">
                        <option value="AL">Alabama</option><option value="AK">Alaska</option><option value="AZ">Arizona</option><option value="AR">Arkansas</option><option value="CA">California</option><option value="CO">Colorado</option><option value="CT">Connecticut</option><option value="DE">Delaware</option><option value="DC">District Of Columbia</option><option value="FL">Florida</option><option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option><option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option><option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option><option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option><option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option><option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option><option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option><option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option><option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option><option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option><option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option><option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option><option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option><option value="WI">Wisconsin</option><option value="WY">Wyoming</option>
                      </Field>
                      <ErrorMessage class="text-danger" name="ship_to_state" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12 col-lg-4">
                      <label for="">ZIP</label>
                      <Field  class="form-control" name="ship_to_zip" v-model="customer.acf['locations']['ship_to'].zip"  :disabled="shipToBillingToSame" />
                      <ErrorMessage class="text-danger" name="ship_to_zip" />
                  </div>
                </div>
                <hr>
                
                <h5>Contact</h5>
                <div class="row">
                  <div class="form-group mt-2 mb-2 col-12">
                      <label for="">Contact</label>
                      <Field  class="form-control" name="contact" v-model="customer.acf.contact"   />
                      <ErrorMessage class="text-danger" name="contact" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12">
                      <label for="">EMail</label>
                      <Field  class="form-control" name="email" v-model="customer.acf.email"   />
                      <ErrorMessage class="text-danger" name="email" />
                  </div>
                  <div class="form-group mt-2 mb-2 col-12">
                      <label for="">Phone</label>
                      <Field  class="form-control" name="phone" v-model="customer.acf.phone" v-mask="`(###) ###-####`"   />
                      <ErrorMessage class="text-danger" name="phone" />
                  </div>
                </div>
                <PageCreateButtons :errors="errors" />
              </fieldset>
            </Form>
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
export default {
  name: 'CustomersEdit',
  components: {
    Field,
    Form,
    ErrorMessage,
    defineRule
  },
  data(){
    return {
      customer: _.merge(..._.map(this.$store.state.db.customers.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      shipToBillingToSame:false,
      schema:yup.object().shape({
          'post_title':yup.string().required('Field is required'),
          'ship_to_address':yup.string().required('Field is required'),
          'ship_to_city':yup.string().required('Field is required'),
          'ship_to_state':yup.string().required('Field is required'),
          'ship_to_zip':yup.string().required('Field is required'),
          'bill_to_address':yup.string().required('Field is required'),
          'bill_to_city':yup.string().required('Field is required'),
          'bill_to_state':yup.string().required('Field is required'),
          'bill_to_zip':yup.string().required('Field is required'),
          contact:yup.string().required('Field is required'),
          email:yup.string().email().required('Field is required'),
          phone:yup.string().required('Field is required'),
          // 'address_1':yup.string().required('Field is required'),
          // 'city':yup.string().required('Field is required'),
          // 'state':yup.string().required('Field is required'),
          // 'zip':yup.string().length(5,'Field must be 5 characters long').required('Field is required'),
      })
    }
  },
  watch:{
    shipToBillingToSame:function(newVal, oldVal){
      if(newVal){
        this.customer.acf['locations']['ship_to'] = JSON.parse(JSON.stringify(this.customer.acf['locations']['bill_to']));
      }
    },
    'customer.acf.locations.bill_to':{
      handler(newVal){
        if(this.shipToBillingToSame){
          this.customer.acf['locations']['ship_to'] = newVal;
        }
      },
      deep:true
    }
  },
  async mounted(){
    let id = [...Array(40)].map(m=>'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.round(Math.random()*62))).join('');
    this.customer.ID = id;
    this.customer.acf = {
      account_number:'',
      contact:'',
      customer_id:id,
      email:'',
      phone:'',
      locations:{ship_to:{address:'',city:'',state:'',zip:''},bill_to:{address:'',city:'',state:'',zip:''}}
    };
    this.ready = true;
  },
  methods:{
    async create(){
      this.busy = true;
      let customer = _.clone(this.customer);
      customer.acf.address_1 = customer.acf.locations.ship_to.address;
      customer.acf.city = customer.acf.locations.ship_to.city;
      customer.acf.state = customer.acf.locations.ship_to.state;
      customer.acf.zip = customer.acf.locations.ship_to.zip;
      customer.acf.ship_to = customer.acf.locations.ship_to;
      customer.acf.bill_to = customer.acf.locations.bill_to.zip;
      customer.post_status = `publish`;
      customer.customer_name = customer.post_title;
      customer.post_type = `customers`;
      customer.ID = [...Array(30)].map(m=>'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.round(Math.random()*62))).join('');
      console.log(customer.acf)
      customer.acf = JSON.stringify(customer.acf);
      await this.$store.state.db.customers.add(customer);
      this.$router.replace(`/customers?success=${btoa('Customer successfully created')}`);
    }
  },
}
</script>
<style lang="scss">
  
</style>