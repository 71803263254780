
<template>
  <div class="page-create-buttons text-end mt-4">
    <MDBBtn tag="a" @click="$router.go(-1)" color="link" class="text-dark" size="md">Back</MDBBtn>
    <MDBBtn type="submit" color="primary" size="md" :disabled="errors!=undefined&&Object.keys(errors).length">Create</MDBBtn>
  </div>
</template>

<script>
export default {
  name: 'PageCreateButtons',
  props: {
    errors:Object
  },
}
</script>
