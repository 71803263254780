<template>
  <div class="customers-edit">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="customer.customer_name" />
            <Form :validation-schema="schema" @submit="update" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Customer</label>
                    <Field  class="form-control" name="post_title" v-model="customer['customer_name']" readonly  />
                    <ErrorMessage class="text-danger" name="post_title" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Address 1</label>
                    <Field  class="form-control" name="address_1" v-model="customer.acf['address_1']"   />
                    <ErrorMessage class="text-danger" name="address_1" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Address 2</label>
                    <Field  class="form-control" name="address_2" v-model="customer.acf['address_2']"   />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Address 3</label>
                    <Field  class="form-control" name="address_3" v-model="customer.acf['address_3']"   />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Address 4</label>
                    <Field  class="form-control" name="address_4" v-model="customer.acf['address_4']"   />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">City</label>
                    <Field  class="form-control" name="city" v-model="customer.acf['city']"   />
                    <ErrorMessage class="text-danger" name="city" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">State</label>
                    <Field  class="form-control" name="state" v-model="customer.acf['state']" as="select">
                        <option value="AL">Alabama</option><option value="AK">Alaska</option><option value="AZ">Arizona</option><option value="AR">Arkansas</option><option value="CA">California</option><option value="CO">Colorado</option><option value="CT">Connecticut</option><option value="DE">Delaware</option><option value="DC">District Of Columbia</option><option value="FL">Florida</option><option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option><option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option><option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option><option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option><option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option><option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option><option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option><option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option><option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option><option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option><option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option><option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option><option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option><option value="WI">Wisconsin</option><option value="WY">Wyoming</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="state" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">ZIP</label>
                    <Field  class="form-control" name="zip" v-model="customer.acf['zip']"   />
                    <ErrorMessage class="text-danger" name="zip" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">County</label>
                    <Field  class="form-control" name="county" v-model="customer.acf['county']"   />
                </div>
                <PageUpdateButtons :errors="errors" />
              </fieldset>
              
            </Form>
            
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
export default {
  name: 'CustomersEdit',
  components: {
    Field,
    Form,
    ErrorMessage,
    defineRule
  },
  data(){
    return {
      customer: _.merge(..._.map(this.$store.state.db.customers.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
          'post_title':yup.string().required('Field is required'),
          'address_1':yup.string().required('Field is required'),
          'city':yup.string().required('Field is required'),
          'state':yup.string().required('Field is required'),
          'zip':yup.string().length(5,'Field must be 5 characters long').required('Field is required'),
      })
    }
  },
  async mounted(){
    this.customer =  _.first(await this.$store.state.db.customers.where('ID').equals((this.$route.params.customer_id)).toArray());
    if(typeof this.customer.acf === 'string') this.customer.acf = JSON.parse(this.customer.acf); 
    this.ready = true;
    console.log(this.customer)
  },
  methods:{
    async update(){
      this.busy = true;
    
      let req = await fetch(`${this.$store.state.api}/customers/${this.customer.ID}?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.customer.acf), p=>p.join('=')).join('&'))))}`, {
        method:'PUT',
        body:JSON.stringify(this.customer.acf),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.push({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        this.$router.push({query:{success:btoa(res.success)}});
        await this.$store.state.db.customers.where('ID').equals(this.customer.ID).modify({acf:JSON.stringify(this.customer.acf)});
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>