<template>
  <div class="territories">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <header><h3>Territories</h3></header>
          <hr>
          <fieldset :disabled="busy">
              <MDBSpinner v-if="busy" />
              <MDBRow>
                <MDBCol class="" col="12" xl="8" lg="8"><!--<input v-model="pagination.searchText" class="form-control" placeholder="Search">--></MDBCol>
                <MDBCol class="d-flex justify-content-end mb-1 align-items-center mt-2 mt-lg-0" col="12" xl="4" lg="4">
                  <MDBDropdown v-model="exportFile">
                    <MDBDropdownToggle @click="exportFile = !exportFile" size="sm">CSV</MDBDropdownToggle>
                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                      <MDBDropdownItem href="#" @click.prevent="exportCSV">Export</MDBDropdownItem>
                      <MDBDropdownItem href="#" @click.prevent="importFile=true">Import</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                  <MDBBtn tag="a" :href="`#/territories/create`" color="primary" size="sm" class="text-uppercase">New</MDBBtn>
                </MDBCol>
              </MDBRow>
              <MDBTable style="width: 100%;" align="middle" responsive sm striped variant="light" class="mt-3 shadow" v-if="$store.state.user.roles.indexOf('administrator')!=-1">
                <thead>
                  <tr>
                    <th>Territory</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(territory,i) in territories" :key="territory.ID">
                    <td>{{territory.post_title}}</td>
                    <td class="d-none d-lg-table-cell"></td>
                    <td class="text-end">
                      <MDBDropdown v-model="tools[i]">
                        <MDBDropdownToggle @click="tools[i] = !tools[i]" size="sm"></MDBDropdownToggle>
                        <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                          <MDBDropdownItem :href="`#/territories/${territory.ID}/edit/`"><MDBIcon icon="pencil-alt" iconStyle="fas" /> Edit</MDBDropdownItem>
                          <MDBDropdownItem href="#" @click.prevent="selected=territory; confirmDelete = true" class="text-danger"><MDBIcon icon="trash-alt" iconStyle="fas" /> Delete</MDBDropdownItem> 
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </td>
                  </tr>
                </tbody>
              </MDBTable>
              <Pagination :pagination="pagination" />
          </fieldset>
        </MDBContainer>
      </transition>
      <MDBModal
        size="sm"
        id="confirmDelete"
        tabindex="-1"
        labelledby="confirmDeleteLabel"
        v-model="confirmDelete"
        :staticBackdrop="busy"
      >
        <MDBModalHeader>
          <MDBModalTitle id="confirmDeleteLabel">Confirm Delete</MDBModalTitle>
        </MDBModalHeader>
        <MDBModalBody>Are you sure you want to delete: {{selected.post_title}}?</MDBModalBody>
        <MDBModalFooter>
          <MDBBtn :disabled="busy" color="link" class="text-dark" size="md" @click="confirmDelete = false; selected={}">Close</MDBBtn>
          <MDBBtn :disabled="busy" color="danger" size="md" @click="remove">Delete</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBModal
        size="lg"
        id="importFile"
        tabindex="-1"
        labelledby="importFileLabel"
        v-model="importFile"
        :staticBackdrop="busy"
      >
        <MDBModalHeader>
          <MDBModalTitle id="importFileLabel">Import CSV</MDBModalTitle>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBFile v-model="file" label="Upload CSV" accept=".csv" />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn :disabled="busy" color="link" class="text-dark" size="md" @click="importFile = false; file=null">Close</MDBBtn>
          <MDBBtn :disabled="busy" color="danger" size="md" @click="importCSV">Upload</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
  </div>
</template>

<script>
import { ref } from 'vue';
import * as yup from 'yup';
export default {
  name: 'Territories',
  components: {
    
  },
  setup(){
    const confirmDelete = ref(false);
    const exportFile = ref(false);
    const importFile = ref(false);
    return {
      confirmDelete,exportFile,importFile
    }
  },
  data(){
    return {
      territories: [],
      selected:{},
      ready:false,
      count:0,
      busy:false,
      searchText:'',
      file:null,
      tools:[],
      pagination:{
          count:0,
          page:1,
          per_page:10,
          pages:1,
          searchText:''
      },
    }
  },
  watch:{
    'pagination.count':async function(){
      this.pagination.page = 1;
    },
    pagination:{
      deep:true,
      async handler(newVal){
        this.territories = await this.$store.state.db.territories.where('post_title').startsWithIgnoreCase(this.pagination.searchText.trim()).offset(((newVal.page-1)*newVal.per_page)).limit(newVal.per_page).toArray();
        this.territories.forEach((r,i)=>{
          this.tools[i] = false;
          if(typeof r.acf == 'string') this.territories[i].acf = JSON.parse(r.acf);
        })

        this.pagination.count = await this.$store.state.db.territories.where('post_title').startsWithIgnoreCase(this.pagination.searchText.trim()).count();
        this.pagination.pages = Math.ceil(this.pagination.count / newVal.per_page);
        window.scrollTo(0,0);
      }
    }
  },
  async mounted(){
    this.pagination.count = await this.$store.state.db.territories.count();
    this.ready = true;
  },
  methods:{
    async exportCSV(){
       let territories = (await this.$store.state.db.territories.toArray());
      territories = territories.map(f=>{
        delete f.acf.managers;
        return {...{ID:f.ID, post_title:f.post_title}, ...f.acf}
      });
      let blob = new  Blob([`${toCSV(_.map(pivot(territories), r=>_.map(r, (a,idx,org,newOrder=[0,1,2,3,4,5])=>org[newOrder[idx]])))}`], {type:'text/csv'});
      let url = URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.download = 'territories.csv';
      a.href = url;
      a.click();
    },
    async importCSV(){
      this.busy = true;
      this.importFile = false;
      this.$root.downloadModal = true;
      this.$root.progress = {
        start:0,
        end:this.$store.state.schema['territories'].max_num_pages,
        progress:0
      };
      await this.upload();
      await this.$root.getSchema();
      await this.$root.getTable('territories');
      this.pagination = {
          count:0,
          page:1,
          per_page:10,
          pages:1,
          searchText:''
      };
      await Sleep(1000);
      this.$root.downloadModal = false;
      this.busy = false;
    },
    async upload(){
      return new Promise(async resolve=>{
        this.$root.message = `Uploading CSV File`;
        let formData = new FormData();
        formData.append('file', this.file[0]);
        let req = await fetch(`${this.$store.state.api}/territories`, {
          method:'POST',
          body:formData,
        });
        let res = await req.json();
        _.each(res.remove_territories, async territory=>{
          await this.$store.state.db.territories.where("ID").equals(parseInt(territory)).delete();
        })
        resolve();
      });
    },
    async remove(){
      this.busy = true;
     
      let req = await fetch(`${this.$store.state.api}/territories/${this.selected.ID}`, {
        method:'DELETE',
        body:JSON.stringify(this.selected.acf),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();

      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        this.confirmDelete = ref(false);
        await this.$store.state.db.territories.delete(this.selected.id);
        this.$router.replace({query:{success:`${btoa(res.success)}`}});
        
        this.pagination = {
          count:0,
          page:1,
          per_page:10,
          pages:1,
          searchText:''
        };
        
      }
      this.busy = false;
    },
    
  }
}
</script>
<style lang="scss">
  
</style>