
<template>
  <div class="page-update-buttons text-end mt-4">
    <MDBBtn tag="a" @click="$router.go(-1)" color="link" size="md"  class="text-dark">Back</MDBBtn>
    <MDBBtn type="submit" color="primary" size="md" :disabled="errors!=undefined&&Object.keys(errors).length">Update</MDBBtn>
  </div>
</template>

<script>
export default {
  name: 'PageUpdateButtons',
  props: {
    errors:Object
  },
}
</script>
