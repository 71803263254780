<template>
  <transition name="fade-fast">
    <div @click="navbarToggle = false" class="navigation-overlay position-fixed top-0 bottom-0 start-0 end-0"
      v-if="navbarToggle"></div>
  </transition>
  <transition name="navbarslideIn">
    <nav class="shadow-lg bg-white pt-4" id="nav" v-if="navbarToggle">
      <MDBIcon icon="times" iconStyle="fas" class="position-absolute p-2 top-0 end-0 me-2"
        @click="navbarToggle = false" />
      <ul class="list-unstyled pt-2 pb-2 ps-2 pe-2">
        <li class="text-uppercase"><a @click="navbarToggle = false" :href="`#/dashboard/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Dashboard</a></li>
        <li class="text-uppercase"><a @click="navbarToggle = false" :href="`#/customers/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Customers</a></li>
        <li v-if="$store.state.user && $store.state.user.roles.indexOf('administrator') != -1" class="text-uppercase"><a
            @click="navbarToggle = false" :href="`#/regions/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Regions</a></li>
        <li v-if="$store.state.user && $store.state.user.roles.indexOf('administrator') != -1" class="text-uppercase"><a
            @click="navbarToggle = false" :href="`#/compressors/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Compressors</a></li>
        <li v-if="$store.state.user && $store.state.user.roles.indexOf('administrator') != -1" class="text-uppercase"><a
            @click="navbarToggle = false" :href="`#/dryers/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Dryers</a></li>
        <li v-if="$store.state.user && $store.state.user.roles.indexOf('administrator') != -1" class="text-uppercase"><a
            @click="navbarToggle = false" :href="`#/filters/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Filters</a></li>
        <li v-if="$store.state.user && $store.state.user.roles.indexOf('administrator') != -1" class="text-uppercase"><a
            @click="navbarToggle = false" :href="`#/territories/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Territories</a></li>
        <li v-if="$store.state.user && $store.state.user.roles.indexOf('administrator') != -1" class="text-uppercase"><a
            @click="navbarToggle = false" :href="`#/gallons/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Gallons</a></li>
        <li v-if="$store.state.user && $store.state.user.roles.indexOf('administrator') != -1" class="text-uppercase"><a
            @click="navbarToggle = false" :href="`#/users/`"
            class="text-black d-block pt-2 pb-2 ps-1 pe-1 border-bottom">Users</a></li>
      </ul>

    </nav>
  </transition>

  <header v-if="Object.keys($store.state.user).length" id="navbar"
    class="d-flex align-items-center justify-content-center position-fixed start-0 w-100 top-0">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col d-flex align-items-center justify-content-start">
          <a href="#/">
            <img src="@/assets/brabazon-logo.svg" style="height: 50px;">
          </a>
        </div>
        <div class="col text-light d-flex align-items-center justify-content-end">
          <MDBIcon @click="navbarToggle = true" icon="bars" size="1x" iconStyle="fas" />
        </div>
      </div>
    </div>
  </header>
  <transition name="fromTop">
    <div v-if="$store.state.success" style="z-index:100;"
      class="position-fixed mt-0 start-0 w-100 rounded-0 pt-2 pb-2 mb-0 alert alert-success"
      v-html="$store.state.success"></div>
  </transition>
  <transition name="fromTop">
    <div v-if="$store.state.danger" style="z-index:100;"
      class="position-fixed mt-0 start-0 w-100 rounded-0 pt-2 pb-2 mb-0 alert alert-danger"
      v-html="$store.state.danger"></div>
  </transition>
  <transition name="fromTop">
    <div v-if="$store.state.info" style="z-index:100;"
      class="position-fixed mt-0 start-0 w-100 rounded-0 pt-2 pb-2 mb-0 alert alert-info" v-html="$store.state.info">
    </div>
  </transition>

  <router-view v-slot="{ Component }">
    <transition name="router-anim" mode="out-in"
      :enter-active-class="`animate__animated animate__${animationIn} animate__faster`"
      :leave-active-class="`animate__animated animate__${animationOut} animate__faster`">
      <component :is="Component" class="page-view" />
    </transition>
  </router-view>
  <transition name="fromBottom">
    <div class="alert alert-danger position-fixed bottom-0 start-0 w-100 mb-0 rounded-0" v-if="!$store.state.online">No
      internet connection.</div>
  </transition>
  <MDBModal id="downloadModal" tabindex="-1" labelledby="downloadModalLabel" v-model="$root.downloadModal"
    staticBackdrop>
    <MDBModalBody class="p-4">
      <h3 class="text-center text-uppercase mb-2">Downloading</h3>
      <h5 class="text-uppercase mb-4 text-center" v-html="message"></h5>
      <transition name="fadeIn">
        <div class="text-center">
          <MDBSpinner />
        </div>
        <!-- <MDBProgress v-if="message!='Downloading Database Structure'&&message!=''" :height="20">
              <MDBProgressBar striped animated :value="progress.progress" />
            </MDBProgress> -->
      </transition>
    </MDBModalBody>
  </MDBModal>


</template>
<script>
import { ref } from 'vue';

export default {
  components: {
  },

  data() {
    return {
      navbarToggle: false,
      animationName: undefined,
      animationIn: 'fadeIn',
      animationOut: 'fadeOut',
      message: '',
      pagination: {
        pages: 0,
        page: 1,
        posts_per_page: 100
      },
      progress: {
        start: 0,
        end: 0,
        progress: 0
      }
    }
  },
  setup() {
    const collapse1 = ref(false);
    const dropdown1 = ref(false);
    const downloadModal = ref(false);
    return {
      collapse1,
      dropdown1,
      downloadModal
    }
  },
  async mounted() {
    this.$store.state.online = navigator.onLine;
    window.addEventListener('offline', () => {
      this.$store.state.online = false;
    });
    window.addEventListener('online', () => {
      this.$store.state.online = true;
    });

  },
  methods: {
    async getSchema() {
      return new Promise(async resolve => {
        this.message = `Downloading Database Structure`;
        let req = await fetch(`${this.$store.state.api}/schema?hash=${this.$store.state.application_password}`, {
          method: 'GET',
          headers: {
            'Authorization': `Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
          }
        });
        let res = await req.json();
        localStorage.setItem('schema', JSON.stringify(res));
        this.$store.state.schema = JSON.parse(localStorage.getItem('schema'));
        resolve();
      })
    },
    async getSingleTable(table) {
      await this.getSchema();
      await this.getTable(table);
      await Sleep(250);
      this.downloadModal = false;
    },
    async getTablePage(table, i, r = undefined) {
      let req, res, arr, obj;
      return new Promise(async (resolve, reject) => {
        try {
          req = await fetch(`${this.$store.state.api}/${table}?paged=${(i + 1)}&hash=${this.$store.state.application_password}`, {
            method: 'GET',
            headers: {
              'Authorization': `Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
            }
          })
          if (req.ok) {
            res = await req.json()
            for (let j = 0; j < res.length; j++) {
              arr = await this.$store.state.db[table].where('ID').equals(res[j].ID).toArray();
              if (!_.isEmpty(arr)) {
                obj = _.first(arr);
                await this.$store.state.db[table].where('id').equals(obj.id).modify(res[j]);
              }
              else {
                await this.$store.state.db[table].add(res[j]);
              }
            }
            if (r == undefined) resolve();
            else r();
            // resolve();
          }
          else {
            if (r == undefined) r = resolve;
            this.getTablePage(table, i, r);
          }
        }
        catch (err) {
          if (r == undefined) r = resolve;
          this.getTablePage(table, i, r);
        }

      })
    },
    async getTable(table) {
      return new Promise(async resolve => {
        this.message = `Downloading Table: ${table}`;
        if (table == 'customers') {
          await this.$store.state.db.customers.clear();
          // let req = await fetch(`${this.$store.state.api}/v1/uploads/customers.csv?v=${new Date().getTime()}`);
          let req = await fetch(`${this.$store.state.api}/customers`);
          let res = await req.text();
          let count = Papa.parse(res).data.length;
          let i = 0;
          let customers = [];
          let data = Papa.parse(res, {
            worker: true,
            step: async (results) => {
              results.data = {
                customer_name: results.data[4],
                post_modified_gmt: '',
                ID: results.data[0],
                post_date: '',
                post_date_gmt: '',
                post_title: '',
                post_type: '',
                post_modified: '',
                acf: ({
                  customer_id: results.data[0],
                  account_number: results.data[3],
                  customer_name: results.data[4],
                  address_1: results.data[5],
                  address_2: '',
                  address_3: '',
                  address_4: '',
                  city: results.data[6],
                  state: results.data[7],
                  zip: results.data[8],
                  county: '',
                  contact: results.data[12],
                  phone: results.data[13],
                  email: results.data[14],
                  locations: {
                    bill_to: {
                      address: results.data[5],
                      city: results.data[6],
                      state: results.data[7],
                      zip: results.data[8],
                    },
                    ship_to: {
                      address: results.data[9],
                      city: results.data[10],
                      zip: results.data[11],
                    }
                  }
                })
              };
              customers.push(results.data);

              // await this.$store.state.db.customers.add(results.data);
            },
            complete: async () => {

              customers = _.chunk(customers, 500);
              let from = 0;
              let to = 0;
              for (let i = 0; i < customers.length; i++) {
                to += customers[i].length;
                from = to - customers[i].length;
                this.$root.message = `Adding Customers ${from} - ${to} out of ${count}`;
                await Sleep(100);
                await this.$store.state.db.customers.bulkAdd(customers[i]);
              }

              await Sleep(1000);
              resolve();
            }
          });
        }
        else {
          let tableData = JSON.parse(localStorage.getItem('schema'))[table];

          for (let i = 0; i < tableData.max_num_pages; i++) {
            await this.getTablePage(table, i);
            // this.progress.start += 1;
            // this.progress.progress = (this.progress.start * (100 / this.progress.end));
          }
          resolve();
        }


      });
    },
    async download() {
      await this.getSchema();
      let tables = Object.keys(JSON.parse(localStorage.getItem('schema')));
      // this.progress.end = _.sum(_.map(_.values(JSON.parse(localStorage.getItem('schema'))), m=>m.max_num_pages));
      // return;
      for (let i = 0; i < tables.length; i++) {
        await this.getTable(tables[i]);
      }
      await Sleep(250);
      this.downloadModal = false;
    },
    async logOut() {
      // this.$store.state.db.compressors.clear();
      // this.$store.state.db.customers.clear();
      // this.$store.state.db.dryers.clear();
      // this.$store.state.db.filters.clear();
      // this.$store.state.db.quotes.clear();
      // this.$store.state.db.regions.clear();
      // this.$store.state.db.territories.clear();
      // this.$store.state.db.users.clear();
      localStorage.removeItem('user');
      // localStorage.removeItem('schema');
      this.$router.replace('/login')
      this.$store.state.user = {};
      this.$store.state.schema = {};
    }
  },
  watch: {
    $route: async function (newVal, oldVal) {
      if (!_.isEmpty(newVal.query)) {
        if (!_.isEmpty(newVal.query.success)) this.$store.state.success = atob(newVal.query.success);
        else this.$store.state.success = '';
        if (!_.isEmpty(newVal.query.info)) this.$store.state.info = atob(newVal.query.info);
        else this.$store.state.info = '';
        if (!_.isEmpty(newVal.query.danger)) this.$store.state.danger = atob(newVal.query.danger);
        else this.$store.state.danger = '';

        await Sleep(3000);
        this.$store.state.success = '';
        this.$store.state.info = '';
        this.$store.state.danger = '';


        this.$router.replace({ query: {} });
      }
      else {
        this.$store.state.success = '';
        this.$store.state.info = '';
        this.$store.state.danger = '';
      }
    }
  },
}
</script>
<style lang="scss">
@import '~@/../mdb/scss/index.free.scss';

:root {
  --bg-primary: #997D4D;
  --text-primary: #997D4D;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
}

.btn {
  &.btn-primary {
    background-color: var(--bg-primary) !important;
  }
}

table {
  .btn {
    i.fas {
      font-size: 1rem !important;
    }
  }
}

.progress-bar {
  background-color: var(--bg-primary) !important;
}

fieldset:disabled {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-color: rgba(#CED4DA, 0.55);
  }

  .spinner-border {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    margin-left: -1rem;
    margin-top: -1rem;
  }
}

.login {
  fieldset:disabled {
    &:after {
      background-color: rgba(#fff, 0.55);
    }
  }
}

#app {
  min-height: 100vh;
  width: 100%;
  background-color: var(--bs-gray-400);
  overflow-x: hidden;
  padding-top: 60px;
  padding-bottom: 1rem;

  .modal {
    .modal-content {
      border-radius: 0px !important;
    }
  }

  .page-container {
    @media (max-width: 720px) {
      max-width: 720px;
    }

    @media (min-width: 720px) {
      max-width: 1200px;
    }
  }

  #navbar {
    height: 60px;
    background-color: var(--mdb-dark);
    z-index: 999;

    .fas {
      cursor: pointer;
      padding: 10px;
    }
  }

  .navigation-overlay {
    z-index: 1050;
    opacity: 0.5;
    background-color: #000;
  }

  nav#nav {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    max-width: 480px;
    z-index: 1050;

    .fas {
      padding: 15px;
      font-size: 1.5rem;
    }
  }

  .rotate {
    animation: rotation 2s infinite linear forwards;
    transform-origin: 50% 50%;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .fromTop- {

    &enter-active,
    &leave-active {
      transition: all 0.5s ease;
    }

    &enter-from,
    &leave-to {
      transform: translateY(-70px);
    }
  }

  .fromBottom- {

    &enter-active,
    &leave-active {
      transition: all 0.5s ease;
    }

    &enter-from,
    &leave-to {
      transform: translateY(70px);
    }
  }

  .fade- {

    &enter-active,
    &leave-active {
      transition: opacity 0.5s ease;
    }

    &enter-from,
    &leave-to {
      opacity: 0;
    }
  }

  .fade-fast- {

    &enter-active,
    &leave-active {
      transition: opacity 0.35s ease;
    }

    &enter-from,
    &leave-to {
      opacity: 0;
    }
  }

  .fadeFromTop- {

    &enter-active,
    &leave-active {
      transition: all 0.5s ease;
    }

    &enter-from,
    &leave-to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  .navbarslideIn- {

    &enter-active,
    &leave-active {
      transition: all 0.35s ease;
    }

    &enter-from,
    &leave-to {
      transform: translateX(100%);
    }
  }

  button,
  a[type="button"],
  a[role="button"],
  input[type="submit"] {
    -webkit-appearance: none;
    background-image: none;

    &.btn-primary {
      background-color: var(--bg-primary) !important;
    }
  }
}

.table-responsive {
  min-height: 260px;
}
</style>
