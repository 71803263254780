<template>
  <div class="users-create">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="`Create User`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <MDBRow>
                  <MDBCol class="form-group mt-2 mb-2" lg="6">
                    <label for="">First Name</label>
                    <Field  class="form-control" name="first_name" v-model="user.first_name"  />
                    <ErrorMessage class="text-danger" name="first_name" />
                  </MDBCol>
                  <MDBCol class="form-group mt-2 mb-2" lg="6">
                    <label for="">Last Name</label>
                    <Field  class="form-control" name="last_name" v-model="user.last_name"  />
                    <ErrorMessage class="text-danger" name="last_name" />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol class="form-group mt-2 mb-2">
                    <label for="">Email Address</label>
                    <Field  class="form-control" name="user_email" v-model="user.user_email"  />
                    <ErrorMessage class="text-danger" name="user_email" />
                  </MDBCol>
                </MDBRow>
                 <MDBRow>
                  <MDBCol class="form-group mt-2 mb-2">
                    <label for="">Phone number</label>
                    <Field  class="form-control" name="phone_number" v-model="user.phone_number" v-mask="'(###) ###-####'" />
                    <ErrorMessage class="text-danger" name="phone_number" />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  
                  <MDBCol class="form-group mt-2 mb-2">
                    <label for="">User Level</label>
                    <Field as="select" name="user_role" class="form-control" v-model="user.roles[0]" >
                      <option value="subscriber">Sales Person</option>
                      <option value="administrator">Administrator</option>
                    </Field>
                  </MDBCol>
                </MDBRow>
                <hr>
                <h4>Territory Numbers</h4>
                <table class="table table-sm table-borderless">
                  <tbody>
                    <tr v-for="(territory,idx) in user.territories" :key="idx">
                      <td  class="ps-1 pe-1" style="vertical-align:top;">
                        <Field  as="select" class="form-control" :name="`territories[${idx}].territory`" v-model="territory.territory">
                          <option value="" disabled>--Select Territory--</option>
                          <option :value="territory" v-for="(territory,idx) in territories" :key="idx">{{territory}}</option>
                        </Field>
                        <ErrorMessage class="text-danger" :name="`territories[${idx}].territory`" />
                      </td>
                      <td class="ps-1 pe-1 text-center " style="vertical-align:middle;"><MDBIcon icon="trash-alt" iconStyle="fas" @click.prevent="user.territories.splice(idx,1)" class="text-danger" /></td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-end">
                  <MDBBtn tag="a" @click.prevent="user.territories.push({territory:''})" color="link" class="text-dark" size="md">Add Territory</MDBBtn>
                </div>
                <hr>
                <h4>Password</h4>
                <transition name="fade"><ErrorMessage class="text-danger alert alert-danger pt-2 pb-2 ps-3 pe-3 rounded-0 d-block" name="verify_password" /></transition>
                <MDBRow>
                  <MDBCol class="form-group mt-2 mb-2" lg="6">
                    <label>Password</label>
                    <Field  type="password" class="form-control" name="user_pass" v-model="user.user_pass"  />
                    <ErrorMessage class="text-danger" name="user_pass" />
                  </MDBCol>
                  <MDBCol class="form-group mt-2 mb-2" lg="6">
                    <label>Verify Password</label>
                    <Field type="password" class="form-control" name="verify_password" v-model="verify_password"  />
                  </MDBCol>
                </MDBRow>
                <PageCreateButtons :errors="errors" />
              </fieldset>
            </Form>
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import * as yup from 'yup';
export default {
  name: 'UsersCreate',
  components: {
  },
  data(){
    return {
      user: _.merge(..._.map(this.$store.state.db.users.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      territories:[],
      verify_password:'',
      schema:yup.object().shape({
        'first_name':yup.string().required('Field is required').label('First name'),
        'last_name':yup.string().required('Field is required').label('Last name'),
        'user_email':yup.string().email().required('Email is required').label('Email'),
        'user_pass':yup.string().required('Password is required').label('Password'),
        'verify_password':yup.string().oneOf([yup.ref('user_pass'),null],'Passwords must match'),
        'phone_number':yup.string().label('Phone number'),
        'territories':yup.array().of(yup.object().shape({
          territory:yup.string().required().label('Territory number')
        })),
      })
    }
  },
  async mounted(){
    this.user.roles = ['subscriber'];
    this.user.user_pass = '';
    this.user.territories = [];
    this.territories = await this.$store.state.db.territories.toArray();
    this.territories = _.map(this.territories,  t=>(typeof t.acf=='string')?JSON.parse(t.acf):t.acf);
    this.territories = _.map(_.flatten(_.filter(_.map(this.territories, t=>t.managers), t=>!_.isEmpty(t))), t=>t.manager_number);
    this.territories = _.uniq(this.territories);
    this.territories = (this.territories.sort((a,b)=>a-b));
    this.ready = true;
  },
  methods:{
    async create(){
      this.busy = true;
      let req = await fetch(`${this.$store.state.api}/users/create?hash=${this.$store.state.application_password}`, {
        method:'POST',
        body:JSON.stringify(this.user),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      else{
        await this.$store.state.db.users.add(res.user);
        this.$router.replace(`/users/${res.user.ID}/edit?success=${btoa(res.success)}`);
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>