<template>
  <div class="customers-quotes-create">
    <transition name="fade">
      <!-- add one more dash to bpc_no and one more number that increments every time it is updated. start it at 1 -->
      <MDBContainer v-if="ready" class="mt-3 page-container">
        <header>
          <h3>Edit Quote: {{ quote.acf.bpc_no }}</h3>
        </header>
        <hr>
        <Form :validation-schema="schema" @submit="update" v-slot="{ errors }" autocomplete="off">
          <fieldset :disabled="busy">
            <MDBSpinner v-if="busy" />
            <div>
              <div class="form-group mt-2 mb-2">
                <h5 class="text-center">Compressors</h5>
                <div v-for="(c, i) in quote.acf.compressors" :key="c.ID" class=" bg-white shadow p-2 mb-1 mt-1">
                  <h6 for="" class="mb-1 d-block border-bottom pb-1">Compressor {{ i + 1 }}</h6>
                  <MDBRow>
                    <MDBCol col="6" lg="6">
                      <label for="">Make</label>
                      <Field as="select" class="form-control mb-1" :name="`compressors[${i}].make`" v-model="c.make">
                        <option value="" disabled>--Select Make--</option>
                        <option value="Sullair">Sullair</option>
                        <option value="Kaishan">Kaishan</option>
                        <option value="Atlas Copco">Atlas Copco</option>
                        <option value="Gardner Denver">Gardner Denver</option>
                        <option value="Ingersoll Rand">Ingersoll Rand</option>
                        <option value="Kaeser">Kaeser</option>
                        <option value="Quincy">Quincy</option>
                        <option value="Boge">Boge</option>
                        <option value="Castair">Castair</option>
                        <option value="Champion">Champion</option>
                        <option value="CompAir">CompAir</option>
                        <option value="Palatek">Palatek</option>
                        <option value="Sullivan">Sullivan</option>
                        <option value="Other">Other</option>
                      </Field>
                      <ErrorMessage class="text-danger" :name="`compressors[${i}].make`" />
                    </MDBCol>
                    <MDBCol col="6" lg="6">
                      <label for="">HP</label>
                      <Field as="select" class="form-control mb-1" :name="`compressors[${i}].hp`" v-model="c.hp"
                        @change="
                          c.number_of_visits = '';
                        c.control_parts = '';
                        (c.hp != '' ? (c.sump = ((compressors.filter(comp => comp.ID == c.hp)[0]).acf.sump)) : (c.sump = ''));
                        (c.hp != '' ? (c.visit_pricing = compressors.filter(comp => comp.ID == c.hp)[0]['acf']) : (c.visit_pricing = ''));
                        (c.gallons = ((c.hp == '55811' || c.hp == '55810' || c.hp == '55812') ? '1' : '0'))">
                        <option value="" disabled>--Select HP--</option>
                        <option v-for="compressor in compressors" :key="compressor.ID" :value="compressor.ID">
                          {{ compressor.post_title }}</option>
                      </Field>
                      <ErrorMessage class="text-danger" :name="`compressors[${i}].hp`" />
                    </MDBCol>
                    <MDBCol cols="12" lg="4">
                      <label for="">Model Number</label>
                      <Field class="form-control mb-1" :name="`compressors[${i}].model_number`" v-model="c.model_number"
                        placeholder="Model Number" />
                      <ErrorMessage class="text-danger" :name="`compressors[${i}].model_number`" />
                    </MDBCol>
                    <MDBCol cols="12" lg="4">
                      <label for="">Serial Number</label>
                      <Field class="form-control mb-1" :name="`compressors[${i}].serial_number`"
                        v-model="c.serial_number" placeholder="Serial Number" />
                      <ErrorMessage class="text-danger" :name="`compressors[${i}].serial_number`" />
                    </MDBCol>

                    <MDBCol cols="12" lg="4">
                      <label for="">System</label>
                      <Field class="form-control mb-1" :name="`c[${i}][sump]`" v-model="c.sump" placeholder="System"
                        readonly />
                    </MDBCol>
                    <MDBCol col="12" lg="4" v-if="c.hp != '55811' && c.hp != '55810' && c.hp != '55812'">
                      <label for="">Additional Oil</label>
                      <Field as="select" class="form-control mb-1" :name="`compressors[${i}].gallons`"
                        v-model="c.gallons">
                        <option selected :value="0">0</option>
                        <option v-for="g in gallons" :key="g.id" :value="g.acf.gallons">{{ g.acf.gallons }} Gal.
                        </option>
                      </Field>
                    </MDBCol>
                    <MDBCol col="12" lg="4">
                      <label for="">Fluid Type</label>
                      <Field as="select" class="form-control mb-1" :name="`compressors[${i}].fluid_type`"
                        v-model="c.fluid_type">
                        <option :value="4000">4,000 Hours</option>
                        <option :value="8000">8,000 Hours</option>
                      </Field>
                    </MDBCol>
                    <!-- v-if="c.visit_pricing!=null&&c.visit_pricing['control_parts_4x_acp']!=0&&c.visit_pricing['control_parts_3x_acp']!=0&&c.visit_pricing['control_parts_2x_acp']!=0&&c.visit_pricing['control_parts_1x_acp']!=0&&c.visit_pricing['500_or_less']!=0" -->
                    <MDBCol col="12" lg="6">
                      <div class="form-group mb-1">
                        <label>Hours Per Year</label>
                        <Field as="select" class="form-control mb-1" :name="`compressors[${i}].control_parts`"
                          v-model="c.control_parts" @change="
                            c.hours = (parseInt([...$event.target.querySelectorAll('option')][$event.target.selectedIndex].innerText.replace(/\,/g, '')));
                          ">
                          <option value="" disabled>Hours Per Year</option>
                          <option v-if="c.visit_pricing != null && c.visit_pricing['control_parts_4x_acp'] != 0"
                            value="control_parts_4x_acp">8,000</option>
                          <option v-if="c.visit_pricing != null && c.visit_pricing['control_parts_3x_acp'] != 0"
                            value="control_parts_3x_acp">6,000</option>
                          <option v-if="c.visit_pricing != null && c.visit_pricing['control_parts_2x_acp'] != 0"
                            value="control_parts_2x_acp">4,000</option>
                          <option v-if="c.visit_pricing != null && c.visit_pricing['control_parts_1x_acp'] != 0"
                            value="control_parts_1x_acp">2,000</option>
                          <option v-if="c.visit_pricing != null && c.visit_pricing['500_or_less'] != 0"
                            value="500_or_less">
                            500 or less</option>
                        </Field>

                        <ErrorMessage class="text-danger" :name="`compressors[${i}].control_parts`" />
                      </div>
                    </MDBCol>
                    <MDBCol col="12" lg="12">
                      <label for="">OEM Parts</label>
                      <MDBSwitch label="OEM Parts" v-model="c.oem_part" :true-value="1" :false-value="0" />
                    </MDBCol>
                  </MDBRow>
                  <div class="text-end">
                    <MDBIcon icon="trash" class="text-danger ps-1 pe-1 pt-2 pb-2" iconStyle="fas"
                      @click="quote.acf.compressors.splice(i, 1)" />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <MDBBtn color="primary"
                  @click="quote.acf.compressors.push({ compressor: null, model_number: '', serial_number: '', sump: '', 'number_of_visits': '', control_parts: '', 'visit_pricing': null, gallons: 0, fluid_type: 8000, oem_part: 0 })">
                  Add Compressor</MDBBtn>
              </div>
            </div>
            <hr>
            <div>
              <div class="form-group mt-2 mb-2">
                <h5 class="text-center">Dryers</h5>
                <div v-for="(d, i) in quote.acf.dryers" :key="d.ID" class=" bg-white shadow p-2 mb-1 mt-1">
                  <h6 for="" class="mb-1 d-block border-bottom pb-1">Dryer {{ i + 1 }}</h6>
                  <MDBRow>
                    <MDBCol cols="12" lg="4">
                      <Field as="select" class="form-control mb-1" :name="`d[${i}][dryer]`" v-model="d.dryer">
                        <option value="">--Select Dryer--</option>
                        <option v-for="dryer in dryers['Refrigerated']" :key="dryer.ID" :value="dryer.ID">
                          {{ dryer.post_title }}</option>
                        <option v-for="dryer in dryers['Heatless Regen']" :key="dryer.ID" :value="dryer.ID">
                          {{ dryer.post_title }}</option>
                        <option v-for="dryer in dryers['Heated Regen']" :key="dryer.ID" :value="dryer.ID">
                          {{ dryer.post_title }}</option>
                        <option v-for="dryer in dryers['Blower Regen']" :key="dryer.ID" :value="dryer.ID">
                          {{ dryer.post_title }}</option>
                      </Field>
                    </MDBCol>
                    <MDBCol cols="12" lg="4">
                      <Field class="form-control mb-1" :name="`d[${i}][model_number]`" v-model="d.model_number"
                        placeholder="Model Number" />
                    </MDBCol>
                    <MDBCol cols="12" lg="4">
                      <Field class="form-control mb-1" :name="`d[${i}][serial_number]`" v-model="d.serial_number"
                        placeholder="Serial Number" />
                    </MDBCol>
                    <MDBCol col="12" lg="6">
                      <div class="form-group mb-1">
                        <!-- hours -->
                        <label>Hours Per Year</label>
                        <Field as="select" class="form-control mb-1" :name="`dryers[${i}].hours`" v-model="d.hours">
                          <option value="" disabled>Hours Per Year</option>
                          <option value="8000">8,000</option>
                          <option value="6000">6,000</option>
                          <option value="4000">4,000</option>
                        </Field>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <div class="text-end">
                    <MDBIcon icon="trash" class="text-danger ps-1 pe-1 pt-2 pb-2" iconStyle="fas"
                      @click="quote.acf.dryers.splice(i, 1)" />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <MDBBtn color="primary"
                  @click="quote.acf.dryers.push({ dryer: null, model_number: '', serial_number: '', hours: '' })">Add
                  Dryer
                </MDBBtn>
              </div>
            </div>
            <hr>
            <div>
              <div class="form-group mt-2 mb-2">
                <h5 class="text-center">Filters</h5>
                <div v-for="(f, i) in quote.acf.filters" :key="f.ID" class=" bg-white shadow p-2 mb-1 mt-1">
                  <h6 for="" class="mb-1 d-block border-bottom pb-1">Filter {{ i + 1 }}</h6>
                  <Field as="select" class="form-control mb-1" :name="`f[${i}][filter]`" v-model="f.filter"
                    @change="
                      f.number_of_visits = '';
                    f.control_parts = '';
                    (f.filter != '' ? (f.visit_pricing = filters.filter(filter => filter.ID == f.filter)[0]['acf']) : (f.visit_pricing = ''))">
                    <option value="">--Select Filter--</option>
                    <option v-for="filter in filters" :key="filter.ID" :value="filter.ID">{{ filter.post_title }}
                    </option>
                  </Field>
                  <MDBCol col="12" lg="6">
                    <div class="form-group mb-1">
                      <label>Hours Per Year</label>
                      <Field as="select" class="form-control mb-1" :name="`f[${i}][control_parts]`"
                        v-model="f.control_parts">
                        <option value="" disabled>Hours Per Year</option>
                        <option v-if="f.visit_pricing != null && f.visit_pricing['control_parts_4x_acp'] != 0"
                          value="control_parts_4x_acp">8,000</option>
                        <option v-if="f.visit_pricing != null && f.visit_pricing['control_parts_3x_acp'] != 0"
                          value="control_parts_3x_acp">6,000</option>
                        <option v-if="f.visit_pricing != null && f.visit_pricing['control_parts_2x_acp'] != 0"
                          value="control_parts_2x_acp">4,000</option>
                      </Field>
                    </div>
                  </MDBCol>
                  <div class="text-end">
                    <MDBIcon icon="trash" class="text-danger ps-1 pe-1 pt-2 pb-2" iconStyle="fas"
                      @click="quote.acf.filters.splice(i, 1)" />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <MDBBtn color="primary"
                  @click="quote.acf.filters.push({ filter: null, control_parts: '', 'visit_pricing': null })">Add Filter
                </MDBBtn>
              </div>
            </div>
            <hr>
            <div>
              <h5 class="text-center">Company</h5>
              <MDBRow>
                <MDBCol col="12" lg="12">
                  <div class="form-group mt-2 mb-2">
                    <label for="">Phone</label>
                    <Field class="form-control" :name="`phone`" v-model="quote.acf.customer.acf.phone"
                      v-mask="`(###) ###-####`" />
                    <ErrorMessage class="text-danger" name="phone" />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol col="12" lg="6">
                  <div class="form-group mt-2 mb-2">
                    <label for="">Address</label>
                    <Field class="form-control" :name="`ship_to.address`"
                      v-model="quote.acf.customer.acf.ship_to.address" />
                    <ErrorMessage class="text-danger" name="ship_to.address" />
                  </div>
                </MDBCol>
                <MDBCol col="12" lg="6">
                  <div class="form-group mt-2 mb-2">
                    <label for="">City</label>
                    <Field class="form-control" :name="`ship_to.city`" v-model="quote.acf.customer.acf.ship_to.city" />
                    <ErrorMessage class="text-danger" name="ship_to.city" />
                  </div>
                </MDBCol>
                <MDBCol col="12" lg="8">
                  <div class="form-group mt-2 mb-2">
                    <label for="">State</label>
                    <Field class="form-control" name="ship_to.state" v-model="quote.acf.customer.acf.ship_to['state']"
                      as="select">
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="ship_to.state" />
                  </div>
                </MDBCol>
                <MDBCol col="12" lg="4">
                  <div class="form-group mt-2 mb-2">
                    <label for="">ZIP</label>
                    <Field class="form-control" name="ship_to.zip" v-model="quote.acf.customer.acf.ship_to.zip" />
                    <ErrorMessage class="text-danger" name="ship_to.zip" />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol col="12" lg="6">
                  <div class="form-group mt-2 mb-2">
                    <label for="">Contact</label>
                    <Field class="form-control" :name="`contact`" v-model="quote.acf.customer.acf.contact" />
                    <ErrorMessage class="text-danger" name="contact" />
                  </div>
                </MDBCol>
                <MDBCol col="12" lg="6">
                  <div class="form-group mt-2 mb-2">
                    <label for="">E-Mail</label>
                    <Field class="form-control" :name="`email`" v-model="quote.acf.customer.acf.email" />
                    <ErrorMessage class="text-danger" name="email" />
                  </div>
                </MDBCol>
              </MDBRow>
              <hr>
              <MDBRow>
                <MDBCol class="form-group mt-2 mb-2">
                  <label for="">Territory Number</label>
                  <select name="territory" class="form-control" v-model="quote.acf.manager_number">
                    <option value="">--SELECT TERRITORY--</option>
                    <option v-for="(territory, i) in user.territories" :key="i">{{ territory }}</option>
                  </select>
                  <!-- <ErrorMessage class="text-danger" name="territory" /> -->
                </MDBCol>
              </MDBRow>
              <PageUpdateButtons :errors="errors" />
            </div>
          </fieldset>
        </Form>
      </MDBContainer>
    </transition>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
import {
  MDBListGroup, MDBListGroupItem,
  MDBProgress,
  MDBProgressBar,
  MDBContainer,
  MDBBtn,
  MDBSpinner, MDBRow, MDBCol, MDBIcon
} from "mdb-vue-ui-kit";
export default {
  name: 'CustomersQuotesCreate',
  components: {
    MDBListGroup,
    MDBListGroupItem,
    MDBProgress,
    MDBProgressBar,
    MDBContainer,
    MDBBtn,
    MDBSpinner,
    MDBRow, MDBCol, MDBIcon,
    Field,
    Form,
    ErrorMessage,
    defineRule
  },
  watch: {
    'quote.acf.compressors': function (newVal, oldVal) {
    }
  },
  data() {
    return {
      quote: _.merge(..._.map(this.$store.state.db.quotes.schema.idxByName, (val, key) => { return { [key]: '' } })),
      compressors: [],
      dryers: [],
      filters: [],
      regions: [],
      gallons: [],
      ready: false,
      busy: false,
      user: _.merge(..._.map(this.$store.state.db.users.schema.idxByName, (val, key) => { return { [key]: '' } })),
      schema: yup.object().shape({
        phone: yup.string().required().label('Phone'),
        contact: yup.string().required().label('Contact name'),
        email: yup.string().email().required().label('Contact E-Mail'),
        // territory: yup.string().required('Field is required'),
        ship_to: yup.object().shape({
          address: yup.string().required().label('Address'),
          city: yup.string().required().label('City'),
          state: yup.string().required().label('State'),
          zip: yup.string().required().label('ZIP'),
        }),
        compressors: yup.array().of(yup.object().shape({
          // compressor:yup.string().nullable().required().label('Compressor'),
          make: yup.string().nullable().required().label('Make'),
          hp: yup.string().nullable().required().label('HP'),
          model_number: yup.string().nullable().required().label('Model Number'),
          serial_number: yup.string().nullable().required().label('Serial Number'),
          control_parts: yup.string().when('hp', {
            is: (hp) => {
              if (hp == undefined) return;
              let sum = (_.sum(_.values(_.omit(this.compressors.filter(comp => comp.ID == hp)[0]['acf'], ['4000', '6000', '8000', 'hp', 'sump']))));
              return sum;
            },
            then: yup.string().required().label('Hours Per Year')
          })
        }))
      }),
    }
  },
  async mounted() {
    this.gallons = await this.$store.state.db.gallons.toArray();
    this.gallons = this.gallons.sort((a, b) => parseFloat(a.acf.gallons) - parseFloat(b.acf.gallons));
    this.gallons = this.gallons.map(g => {
      if (typeof g.acf == 'string') g.acf = JSON.parse(g.acf);
      return g;
    });
    this.compressors = await this.$store.state.db.compressors.orderBy('post_title').toArray();
    for (let i = 0; i < this.compressors.length; i++) {
      this.compressors[i].order = (this.compressors[i].post_title.split(' ')[0]);
      if (this.compressors[i].order != 'Piston') this.compressors[i].order = parseFloat(this.compressors[i].order);
    }
    this.compressors = this.compressors.sort((a, b) => a.order - b.order);
    this.compressors = this.compressors.map(c => {
      // c.oem_part
      if (!c.hasOwnProperty('oem_part')) c.oem_part = 0;
      return c;
    });
    this.dryers = await this.$store.state.db.dryers.orderBy('post_title').toArray();
    this.dryers = _.forEach(this.dryers, dryer => dryer.type = dryer.post_title.split(' - ')[0]);
    this.dryers = _.groupBy(this.dryers, 'type');

    Object.keys(this.dryers).forEach(type => {
      for (let i = 0; i < this.dryers[type].length; i++) {
        this.dryers[type][i].order = parseFloat(this.dryers[type][i]['post_title'].split(' - ')[1]);
      }
    });

    Object.keys(this.dryers).forEach(type => {
      for (let i = 0; i < this.dryers[type].length; i++) {
        this.dryers[type] = this.dryers[type].sort((a, b) => a.order - b.order);
      }
    });
    this.filters = await this.$store.state.db.filters.orderBy('post_title').toArray();
    for (let i = 0; i < this.filters.length; i++) {
      this.filters[i].order = parseFloat(this.filters[i].post_title.split(' ')[0]);
    }
    this.filters = this.filters.sort((a, b) => a.order - b.order);
    this.regions = await this.$store.state.db.regions.orderBy('post_title').toArray();
    this.customer = _.first(await this.$store.state.db.customers.where('ID').equals((this.$route.params.customer_id)).toArray());

    this.user = await this.$store.state.db.users.where({ 'ID': this.$store.state.user.ID }).first();
    if (typeof this.user.roles === 'string') this.user.roles = JSON.parse(this.user.roles);
    if (typeof this.user.territories === 'string') this.user.territories = JSON.parse(this.user.territories);
    if (this.user.ID == 1 || this.user.ID == 14) {
      this.user.territories = await this.$store.state.db.territories.toArray();
      this.user.territories = _.map(this.user.territories, t => (typeof t.acf == 'string') ? JSON.parse(t.acf) : t.acf);
      this.user.territories = _.map(_.flatten(_.filter(_.map(this.user.territories, t => t.managers), t => !_.isEmpty(t))), t => t.manager_number);
      this.user.territories = _.uniq(this.user.territories);
      this.user.territories = (this.user.territories.sort((a, b) => a - b));
      this.user.territories = (this.user.territories.sort((a, b) => {
        if (a > b) return 1;
        else if (a < b) return -1;
        else return 0;
      }));
    }
    else {
      this.user.territories = this.user.territories.map(t => t.territory);
      this.user.territories = (this.user.territories.sort((a, b) => {
        if (a > b) return 1;
        else if (a < b) return -1;
        else return 0;
      }));
    }
    this.quote = await this.$store.state.db.quotes.where({ ID: (this.$route.params.quote_id) }).first();
    if (typeof this.quote.acf === 'string') this.quote.acf = JSON.parse(this.quote.acf);
    this.ready = true;
    this.quote.acf.compressors = this.quote.acf.compressors.map(c => {
      if (!c.hasOwnProperty('fluid_type')) c.fluid_type = 8000;
      return c;
    });

    console.log(this.quote.acf.compressors)

  },
  methods: {

    async update() {
      this.busy = true;
      let quote = _.clone(this.quote);
      let bpc_no = quote.acf.bpc_no.split('-');
      (bpc_no[bpc_no.length - 1] = parseFloat(bpc_no[bpc_no.length - 1]) + 1);
      quote.acf.bpc_no = bpc_no.join('-');
      // console.log(parseInt(quote.acf.bpc_no.split('-')[quote.acf.bpc_no.split('-').length-1])+1);
      quote.post_modified = new Date().toUTCString();
      //   // quote.post_date = 'Mon, 23 Nov 2021 19:51:00 GMT';
      quote.post_modified_gmt = new Date().toUTCString();
      //   // quote.post_date_gmt = 'Mon, 23 Nov 2021 19:51:00 GMT';
      let start = (await this.$store.state.db.territories.where({ post_type: 'territories' }).and(t => {
        return t.acf.managers && (t.acf.managers.filter(m => m.manager_number == this.quote.acf.manager_number)).length;
      }).toArray())[0];
      let end = this.quote.acf.customer;
      this.quote.acf.customer.acf.ship_to.zip = this.quote.acf.customer.acf.ship_to.zip.split('-')[0];
      let formData = new FormData();
      formData.append('ship_to', JSON.stringify(this.quote.acf.customer.acf.ship_to));
      let req = await fetch(`${this.$store.state.api}/distance?hash=${this.$store.state.application_password}`, {
        body: formData,
        method: 'POST',
        headers: {
          'Authorization': `Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      quote.acf.distance = (_.minBy(res, 'value').text);
      quote.acf = JSON.stringify(quote.acf)
      await this.$store.state.db.quotes.where('id').equals(quote.id).modify(quote);

      let pdf = await this.createPDF(quote.ID, quote);
      let excel = await this.createExcel(quote.ID, quote);

      formData = new FormData();
      formData.append('quote', JSON.stringify(quote));
      formData.append('pdf', pdf);
      formData.append('excel', excel);
      req = await fetch(`${this.$store.state.api}/email?v=${new Date().getTime()}`, {
        body: formData,
        method: 'POST',
        headers: {
          'Authorization': `Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      res = await req.json();


      this.$router.replace({ query: { success: btoa('Quote successfully updated') } });
      this.busy = false;
    },
    async createPDF(quoteId, quote) {
      return new Promise(async resolve => {
        this.$store.state.pdfWorker = new Worker('./js/PDFWorker.js?v=' + (new Date().getTime())),

          this.$store.state.pdfWorker.addEventListener('message', async e => {
            if (this.$store.state.pdfWorker.hasOwnProperty('terminate')) this.$store.state.pdfWorker.terminate();
            resolve(e.data.blob);
          });

        this.$store.state.pdfWorker.postMessage({ user: this.$store.state.user.ID, ID: quoteId });
      });
    },
    async createExcel(quoteId, quote) {
      return new Promise(async resolve => {
        this.$store.state.excelWorker = new Worker('./js/ExcelWorker.js?v=' + (new Date().getTime())),

          this.$store.state.excelWorker.addEventListener('message', async e => {
            if (this.$store.state.excelWorker.hasOwnProperty('terminate')) this.$store.state.excelWorker.terminate();
            resolve(e.data.blob);
          });
        this.$store.state.excelWorker.postMessage({ user: this.$store.state.user.ID, ID: quoteId });
      });
    }
  },
}
</script>
<style lang="scss">
.customers-quotes-create {}
</style>