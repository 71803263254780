<template>
  <div class="login h-100 d-flex align-items-start align-items-lg-center justify-content-center p-2">
    <MDBContainer>
      <MDBRow>
        <MDBCol lg="6" class="offset-lg-3">
          <Form :class="`bg-white p-5 shadow-sm border ${(error ? ' animate__animated animate__headShake' : '')}`"
            :validation-schema="schema" @submit="login" v-slot="{ errors }" autocomplete="false">
            <div class="text-center mb-2"><img src="@/assets/brabazon.svg" style="max-width:260px;"></div>
            <fieldset :disabled="busy">
              <MDBSpinner v-if="busy" />
              <hr>
              <transition name="fade">
                <div class="alert alert-danger" v-if="error">{{ error }}</div>
              </transition>
              <div class="form-group mt-2 mb-2">
                <label for="">Email Address</label>
                <Field class="form-control" name="user_login" v-model="form.user_login" autocomplete="false"
                  autofocus />
                <ErrorMessage class="text-danger" name="user_login" />
              </div>
              <div class="form-group mb-2 mt-2">
                <label for="">Password</label>
                <Field type="password" class="form-control" name="user_password" v-model="form.user_password"
                  autocomplete="false" />
                <ErrorMessage class="text-danger" name="user_password" />
              </div>
              <div class="text-start mt-4"><button type="submit" class="btn btn-lg btn-dark rounded-0"
                  :disabled="Object.keys(errors).length">LOGIN</button></div>
            </fieldset>
          </Form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
</template>

<script>
// @ is an alias to /src
// import LoginForm from '@/components/Login/LoginForm.vue'
import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBSpinner
} from "mdb-vue-ui-kit";
export default {
  name: 'login',
  components: {
    Field,
    Form,
    MDBSpinner,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    ErrorMessage,
    defineRule
  },
  created() {
    if (!_.isEmpty(localStorage.getItem('user'))) this.$router.push('/dashboard');
  },
  data() {
    return {
      error: '',
      ready: false,
      busy: false,
      form: {
        user_login: '',
        user_password: '',
      },
      schema: yup.object().shape({
        user_login: yup.string().required().email().label('Email Address'),
        user_password: yup.string().required().label('Password')
      })
    }
  },
  async mounted() {
    this.ready = true;
  },
  methods: {
    async login() {
      this.busy = true;
      this.error = '';
      let hash = `${this.$store.state.application_password}${sha256(JSON.stringify(this.form))}`;
      let req = await fetch(`${this.$store.state.api}/login`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${btoa(`${this.form.user_login}:${this.form.user_password}`)}`
        },
        body: JSON.stringify({ ...this.form, ...{ hash: hash } })
      });

      let res = await req.json();

      if (res.hasOwnProperty('error')) {
        this.error = res.error;
        this.busy = false;
        return;
      }
      this.$store.state.user = res;
      localStorage.setItem('user', JSON.stringify(this.$store.state.user));
      this.ready = false;
      await Sleep(this.$root.animationTime);
      this.$router.push('/dashboard');

    }
  }
}
</script>
