import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
/** */
import Dryers from '../views/Dryers.vue'
  import DryersEdit from '../views/Dryers/Edit.vue'
  import DryersCreate from '../views/Dryers/Create.vue'
  // import DryersEditAll from '../views/DryersEditAll.vue'
/** */
/** */
import Filters from '../views/Filters.vue'
  import FiltersEdit from '../views/Filters/Edit.vue'
  import FiltersCreate from '../views/Filters/Create.vue'
/** */
/** */
import Territories from '../views/Territories.vue'
  import TerritoriesEdit from '../views/Territories/Edit.vue'
  import TerritoriesCreate from '../views/Territories/Create.vue'
/** */
/** */
import Gallons from '../views/Gallons.vue'
  import GallonsEdit from '../views/Gallons/Edit.vue'
  import GallonsCreate from '../views/Gallons/Create.vue'
/** */
/** */
import Customers from '../views/Customers.vue'
  // import CustomersView from '../views/Customers/View.vue'
  import CustomersEdit from '../views/Customers/Edit.vue'
  import CustomersCreate from '../views/Customers/Create.vue'
  import CustomersQuotes from '../views/Customers/Quotes.vue'
  import CustomersQuotesCreate from '../views/Customers/Quotes/Create.vue'
  import CustomersQuotesEdit from '../views/Customers/Quotes/Edit.vue'
  
/** */
/** */
import Regions from '../views/Regions.vue'
  import RegionsEdit from '../views/Regions/Edit.vue'
  import RegionsCreate from '../views/Regions/Create.vue'
  import RegionsEditAll from '../views/Regions/EditAll.vue'
/** */
/** */
import Compressors from '../views/Compressors.vue'
  import CompressorsEdit from '../views/Compressors/Edit.vue'
  import CompressorsCreate from '../views/Compressors/Create.vue'
  import CompressorsEditAll from '../views/Compressors/EditAll.vue'
/** */
import Users from '../views/Users.vue'
  import UsersEdit from '../views/Users/Edit.vue'
  import UsersCreate from '../views/Users/Create.vue'
const routes = [
  /** */
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    alias:'/'
  },
  /** */
  /** */
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  /** */
  /** */
  {
    path: '/compressors',
    name: 'Compressors',
    component: Compressors
  },
    {
      path: '/compressors/:compressor_id/edit',
      name: 'CompressorsEdit',
      component: CompressorsEdit
    },
    {
      path: '/compressors/create',
      name: 'CompressorsCreate',
      component: CompressorsCreate
    },
    {
      path: '/compressors/editall',
      name: 'compressors',
      component: CompressorsEditAll
    },
  /** */
  /** */
  {
    path: '/dryers',
    name: 'Dryers',
    component: Dryers
  },
  // {
  //   path: '/dryers-edit-all',
  //   name: 'DryersEditAll',
  //   component: DryersEditAll
  // },
    {
      path: '/dryers/:dryer_id/edit',
      name: 'DryersEdit',
      component: DryersEdit
    },
    {
      path: '/dryers/create',
      name: 'DryersCreate',
      component: DryersCreate
    },
  /** */
  /** */
  {
    path: '/filters',
    name: 'Filters',
    component: Filters
  },
    {
      path: '/filters/:filter_id/edit',
      name: 'FiltersEdit',
      component: FiltersEdit
    },
    {
      path: '/filters/create',
      name: 'FiltersCreate',
      component: FiltersCreate
    },
  /** */
  /** */
  {
    path: '/territories',
    name: 'Territories',
    component: Territories
  },
    {
      path: '/territories/:territory_id/edit',
      name: 'TerritoriesEdit',
      component: TerritoriesEdit
    },
    {
      path: '/territories/create',
      name: 'TerritoriesCreate',
      component: TerritoriesCreate
    },
  /** */
  /** */
  {
    path: '/gallons',
    name: 'Gallons',
    component: Gallons
  },
    {
      path: '/gallons/:gallon_id/edit',
      name: 'GallonsEdit',
      component: GallonsEdit
    },
    {
      path: '/gallons/create',
      name: 'GallonsCreate',
      component: GallonsCreate
    },
  // /** */
  /** */
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
      {
        path: '/customers/:customer_id/edit',
        name: 'CustomersEdit',
        component: CustomersEdit
      },
      {
        path: '/customers/create',
        name: 'CustomersCreate',
        component: CustomersCreate
      },
      {
        path: '/customers/:customer_id/quotes',
        name: 'CustomersQuotes',
        component: CustomersQuotes
      },
      {
        path: '/customers/:customer_id/quotes/create',
        name: 'CustomersQuotesCreate',
        component: CustomersQuotesCreate
      },
      {
        path: '/customers/:customer_id/quotes/:quote_id/edit',
        name: 'CustomersQuotesEdit',
        component: CustomersQuotesEdit
      },
  /** */
  /** */
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
    {
      path: '/users/:user_id/edit',
      name: 'UsersEdit',
      component: UsersEdit
    },
    {
      path: '/users/create',
      name: 'UsersCreate',
      component: UsersCreate
    },
  /** */
  {
    path: '/regions',
    name: 'Regions',
    component: Regions
  },
    {
      path: '/regions/:region_id/',
      name: 'RegionsEdit',
      component: RegionsEdit
    },
    {
      path: '/regions/create',
      name: 'RegionsCreate',
      component: RegionsCreate
    },
    {
      path: '/regions/editall',
      name: 'RegionsEditAll',
      component: RegionsEditAll
    }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
