
<template>
  <div class="custom-header">
    <header><h3>{{title}}</h3></header>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    title: String
  },
}
</script>
