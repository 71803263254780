<template>
  <div class="compressors-create">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
            <Pageheader :title="`Create Compressor`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Compressor</label>
                    <Field  class="form-control" name="post_title" v-model="compressor['post_title']"   />
                    <ErrorMessage class="text-danger" name="post_title" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">500 or Less</label>
                    <Field  type="number" class="form-control" name="500_or_less" v-model="compressor.acf['500_or_less']"  />
                    <ErrorMessage class="text-danger" name="500_or_less" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">2000</label>
                    <Field type="number" class="form-control" name="2000" v-model="compressor.acf['2000']"  />
                    <ErrorMessage class="text-danger" name="2000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">4000</label>
                    <Field type="number" class="form-control" name="4000" v-model="compressor.acf['4000']"  />
                    <ErrorMessage class="text-danger" name="4000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">6000</label>
                    <Field type="number" class="form-control" name="6000" v-model="compressor.acf['6000']"  />
                    <ErrorMessage class="text-danger" name="6000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">8000</label>
                    <Field type="number" class="form-control" name="8000" v-model="compressor.acf['8000']"  />
                    <ErrorMessage class="text-danger" name="8000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Control Parts 1x ACP</label>
                    <Field type="number" class="form-control" name="control_parts_1x_acp" v-model="compressor.acf['control_parts_1x_acp']"  />
                    <ErrorMessage class="text-danger" name="control_parts_1x_acp" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Control Parts 2x ACP</label>
                    <Field type="number" class="form-control" name="control_parts_2x_acp" v-model="compressor.acf['control_parts_2x_acp']"  />
                    <ErrorMessage class="text-danger" name="control_parts_2x_acp" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Control Parts 3x ACP</label>
                    <Field type="number" class="form-control" name="control_parts_3x_acp" v-model="compressor.acf['control_parts_3x_acp']"  />
                    <ErrorMessage class="text-danger" name="control_parts_3x_acp" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Control Parts 4x ACP</label>
                    <Field type="number" class="form-control" name="control_parts_4x_acp" v-model="compressor.acf['control_parts_4x_acp']"  />
                    <ErrorMessage class="text-danger" name="control_parts_4x_acp" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">Sump</label>
                    <Field type="number" class="form-control" name="sump" v-model="compressor.acf['sump']"  />
                    <ErrorMessage class="text-danger" name="sump" />
                </div>
                <PageCreateButtons :errors="errors" />
              </fieldset>
              
            </Form>
            
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
export default {
  name: 'compressorsEdit',
  components: {
    Field,
    Form,
    ErrorMessage,
    defineRule
  },
  data(){
    return {
      compressor: _.merge(..._.map(this.$store.state.db.compressors.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
        'post_title':yup.string().required('Field is required'),
        '500_or_less':yup.string().matches(/\d+/).required('Must be a valid number'),
        '2000':yup.string().matches(/\d+/).required('Must be a valid number'),
        '4000':yup.string().matches(/\d+/).required('Must be a valid number'),
        '6000':yup.string().matches(/\d+/).required('Must be a valid number'),
        '8000':yup.string().matches(/\d+/).required('Must be a valid number'),
        'control_parts_1x_acp':yup.string().matches(/\d+/).required('Must be a valid number'),
        'control_parts_2x_acp':yup.string().matches(/\d+/).required('Must be a valid number'),
        'control_parts_3x_acp':yup.string().matches(/\d+/).required('Must be a valid number'),
        'control_parts_4x_acp':yup.string().matches(/\d+/).required('Must be a valid number'),
        'sump':yup.string().matches(/\d+/).required('Must be a valid number'),
      })
    }
  },
  async mounted(){
    this.compressor.acf = {'500_or_less':'0', '2000':'0','4000':'0','6000':'0','8000':'0','control_parts_1x_acp':'0','control_parts_2x_acp':'0','control_parts_3x_acp':'0','control_parts_4x_acp':'0','sump':'0'};
    this.ready = true;
  },
  methods:{
    async create(){
      this.busy = true;
      this.compressor.post_author = `${parseInt(this.$store.state.user.ID)}`;
      this.compressor.post_status = `publish`;
      this.compressor.post_type = `compressors`;
    
      let req = await fetch(`${this.$store.state.api}/compressors/create?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.compressor.acf), p=>p.join('=')).join('&'))))}`, {
        method:'POST',
        body:JSON.stringify(this.compressor),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        // this.$router.push({query:{success:btoa(res.success)}});
        await this.$store.state.db.compressors.add(res.compressor);
        this.$router.replace(`/compressors/${res.compressor.ID}/edit?success=${btoa(res.success)}`);
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>
