<template>
  <div class="filters-create">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="`Create Filter`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Filter</label>
                    <Field  class="form-control" name="post_title" v-model="filter['post_title']"   />
                    <ErrorMessage class="text-danger" name="post_title" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">4000 Hours</label>
                    <Field  type="number" class="form-control" name="4000_hours" v-model="filter.acf['4000_hours']"  />
                    <ErrorMessage class="text-danger" name="4000_hours" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">6000 Hours</label>
                    <Field type="number" class="form-control" name="6000_hours" v-model="filter.acf['6000_hours']"  />
                    <ErrorMessage class="text-danger" name="6000_hours" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">8000 Hours</label>
                    <Field type="number" class="form-control" name="8000_hours" v-model="filter.acf['8000_hours']"  />
                    <ErrorMessage class="text-danger" name="8000_hours" />
                </div>
                <PageCreateButtons :errors="errors" />
              </fieldset>
              
            </Form>
            
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
import {
    MDBListGroup, MDBListGroupItem,
    MDBProgress,
    MDBProgressBar,
    MDBContainer,
    MDBBtn,
    MDBSpinner
} from "mdb-vue-ui-kit";
export default {
  name: 'filtersEdit',
  components: {
    MDBListGroup,
    MDBListGroupItem,
    MDBProgress,
    MDBProgressBar,
    MDBContainer,
    MDBBtn,
    MDBSpinner,
    Field,
    Form,
    ErrorMessage,
    defineRule
  },
  data(){
    return {
      filter: _.merge(..._.map(this.$store.state.db.filters.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
        'post_title':yup.string().required('Field is required'),
        '4000_hours':yup.string().matches(/\d+/).required('Must be a valid number'),
        '6000_hours':yup.string().matches(/\d+/).required('Must be a valid number'),
        '8000_hours':yup.string().matches(/\d+/).required('Must be a valid number'),
      })
    }
  },
  async mounted(){
    this.filter.acf = {'4000_hours':'', '6000_hours':'','8000_hours':''};
    this.ready = true;
  },
  methods:{
    async create(){
      this.busy = true;
      this.filter.post_author = `${parseInt(this.$store.state.user.ID)}`;
      this.filter.post_status = `publish`;
      this.filter.post_type = `filters`;
      
      let req = await fetch(`${this.$store.state.api}/filters/create?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.filter.acf), p=>p.join('=')).join('&'))))}`, {
        method:'POST',
        body:JSON.stringify(this.filter),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        // this.$router.push({query:{success:btoa(res.success)}});
        await this.$store.state.db.filters.add(res.filter);
        this.$router.replace(`/filters/${res.filter.ID}/edit?success=${btoa(res.success)}`);
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>
