import { createStore } from "vuex";

export default createStore({
  state: {
    user:
      localStorage.getItem("user") == null
        ? {}
        : JSON.parse(localStorage.getItem("user")),
    // api: "https://test.firepixel.com/brabazon/api",
    api: "https://api.brabazon.us/api",
    // api: "https://dev.firepixel.com/brabazon/api",
    application_password:
      "e0fd4fa299e07b3befdef1828fe3849f1f4155017fd1ebd10930a01c80f3b11b",
    db: new Dexie("db"),
    schema:
      localStorage.getItem("schema") == null
        ? {}
        : JSON.parse(localStorage.getItem("schema")),
    ready: false,
    transitionTime: 500,
    success: "",
    danger: "",
    info: "",
    pdfWorker: new Object(),
    excelWorker: new Object(),
    online: navigator.online,
  },
  mutations: {},
  actions: {},
  modules: {},
});
