<template>
  <div class="regions-edit">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="region.acf.region" />
            <Form :validation-schema="schema" @submit="update" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Region</label>
                    <Field  class="form-control" name="region" v-model="region.acf['region']" readonly  />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">2 Visits</label>
                    <Field  type="number" class="form-control" name="2_visits" v-model="region.acf['2_visits']"  />
                    <ErrorMessage class="text-danger" name="2_visits" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">3 Visits</label>
                    <Field type="number" class="form-control" name="3_visits" v-model="region.acf['3_visits']"  />
                    <ErrorMessage class="text-danger" name="3_visits" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">4 Visits</label>
                    <Field type="number" class="form-control" name="4_visits" v-model="region.acf['4_visits']"  />
                    <ErrorMessage class="text-danger" name="4_visits" />
                </div>
                <hr>
                <div class="form-group mt-2 mb-2">
                  <label for="">Mile Range</label>
                  <MDBRow>
                    <MDBCol>
                      <label for="">Min</label>
                      <Field type="number" class="form-control" :name="`min`" v-model="region.acf['range']['min']"  />
                      <ErrorMessage class="text-danger" :name="`min`" />
                    </MDBCol>
                    <MDBCol>
                      <label for="">Min</label>
                      <Field type="number" class="form-control" :name="`max`" v-model="region.acf['range']['max']"  />
                      <ErrorMessage class="text-danger" :name="`max`" />
                    </MDBCol>
                  </MDBRow>
                </div>
                <PageUpdateButtons :errors="errors" />
              </fieldset>
            </Form>
        </MDBContainer>
      </transition>
  </div>
</template>

<script>
import * as yup from 'yup';
export default {
  name: 'RegionsEdit',
  components: {
   
  },
  watch:{
    ['region.acf.range']:{
      deep:true,
      handler(newVal){
        this.region.acf.region = `(${newVal.min}-${newVal.max} mi o/w)`;    
      }
    }
  },
  data(){
    return {
      region: _.merge(..._.map(this.$store.state.db.regions.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
        '2_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '3_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '4_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        min:yup.string().matches(/\d+/).required('Must be a valid number'),
        max:yup.string().matches(/\d+/).required('Must be a valid number')
      })
    }
  },
  async mounted(){
    this.region =  _.first(await this.$store.state.db.regions.where('ID').equals(parseFloat(this.$route.params.region_id)).toArray());
    if(typeof this.region.acf === 'string') this.region.acf = JSON.parse(this.region.acf); 
    this.ready = true;
  },
  methods:{
    async update(){
      this.busy = true;
      let req = await fetch(`${this.$store.state.api}/regions/${this.region.ID}?hash=${this.$store.state.application_password}`, {
        method:'PUT',
        body:JSON.stringify(this.region.acf),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        this.$router.replace({query:{success:btoa(res.success)}});
        await this.$store.state.db.regions.where('ID').equals(this.region.ID).modify({acf:JSON.stringify(this.region.acf)});
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>