<template>
  <div class="custom-pagination">
    <MDBRow>
      <MDBCol cols="12" class="text-start"><MDBBtn :disabled="pagination.page==1" @click.stop="pagination.page--" color="dark" size="sm"><MDBIcon icon="angle-left" iconStyle="fas" /></MDBBtn></MDBCol>
      <MDBCol cols="12" class="text-end"><MDBBtn :disabled="pagination.page==pagination.pages" @click.stop="pagination.page++" color="dark" size="sm"><MDBIcon icon="angle-right" iconStyle="fas" /></MDBBtn></MDBCol>
    </MDBRow>
    <MDBRow class="mt-2">
      <MDBCol class="text-end">
        <i class="text-muted">Page {{pagination.pages==0?0:pagination.page}}/{{pagination.pages}}</i>
      </MDBCol>
    </MDBRow>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    pagination: Object
  },
}
</script>