import { _ } from "core-js";
import { createApp } from "vue";
// import VueMeta from 'vue-meta'
import {
  MDBRadio,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBFile,
  MDBProgressBar,
  MDBProgress,
  MDBSwitch,
} from "mdb-vue-ui-kit";
import { Field, Form, ErrorMessage, defineRule } from "vee-validate";
import Pagination from "./components/Pagination.vue";
import Pageheader from "./components/Pageheader.vue";
import PageCreateButtons from "./components/PageCreateButtons.vue";
import PageUpdateButtons from "./components/PageUpdateButtons.vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "animate.css";
import VueTheMask from "vue-the-mask";

const app = createApp({
  ...{
    components: {},
    watch: {
      "$store.state.user": function (newVal) {},
    },

    data() {
      return {
        animationTime: 500,
      };
    },
    async create() {
      if (_.isEmpty(localStorage.getItem("uuid")))
        localStorage.setItem("uuid", uuidv4());
    },
    async mounted() {},
    methods: {
      async download() {},
    },
  },
  ...App,
})
  .use(store)
  .use(router);

app.config.globalProperties.$store.state.db
  .version(5)
  .stores({
    compressors:
      "++id, ID,acf, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type",
    compressors:
      "++id, ID,acf, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type",
    dryers:
      "++id, ID, acf, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type",
    regions:
      "++id, ID, acf, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type",
    customers:
      "++id, ID, acf, customer_name, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type",
    filters:
      "++id, ID, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type",
    territories:
      "++id, ID, acf, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type",
    quotes:
      "++id, ID, acf, filter, post_date, post_date_gmt, post_modified, post_modified_gmt, post_title, post_type",
    users:
      "++id, ID, first_name, last_name, roles, territories, user_email, phone_number",
    gallons:
      "++id, ID, post_date, post_date_gmt, post_title, post_modified, post_modified_gmt,post_type,acf",
  })
  .upgrade((trans) => {
    // return trans.users.toCollection().modify(u=>{
    //     u.first_name= u.first_name;
    //     u.last = u.last_name;
    //     u.roles = u.roles;
    //     u.territories = (_.isEmpty(u.territories)?[]:u.territories);
    //     u.user_email = u.user_email;
    //     u.phone_number= (_.isEmpty(u.phone_number)?'':u.phone_number);
    // })
  });

if (_.isEmpty(app.config.globalProperties.$store.state.user))
  app.config.globalProperties.$router.push("/login");
else {
  // if() app.config.globalProperties.$router.push('/dashboard');
}
// app.use(VueMeta)
app.component("MDBProgressBar", MDBProgressBar);
app.component("MDBProgress", MDBProgress);
app.component("MDBFile", MDBFile);
app.component("MDBDropdownToggle", MDBDropdownToggle);
app.component("MDBDropdownMenu", MDBDropdownMenu);
app.component("MDBDropdownItem", MDBDropdownItem);
app.component("MDBDropdown", MDBDropdown);
app.component("MDBContainer", MDBContainer);
app.component("MDBBtn", MDBBtn);
app.component("MDBIcon", MDBIcon);
app.component("MDBCol", MDBCol);
app.component("MDBRow", MDBRow);
app.component("MDBSwitch", MDBSwitch);
app.component("MDBTable", MDBTable);
app.component("MDBModal", MDBModal);
app.component("MDBModalHeader", MDBModalHeader);
app.component("MDBModalTitle", MDBModalTitle);
app.component("MDBModalBody", MDBModalBody);
app.component("MDBModalFooter", MDBModalFooter);
app.component("MDBSpinner", MDBSpinner);
app.component("Field", Field);
app.component("MDBRadio", MDBRadio);
app.component("Form", Form);
app.component("ErrorMessage", ErrorMessage);
app.component("defineRule", defineRule);
app.component("Pagination", Pagination);
app.component("Pageheader", Pageheader);
app.component("PageCreateButtons", PageCreateButtons);
app.component("PageUpdateButtons", PageUpdateButtons);

app.use(VueTheMask);
app.mount("#app");
