<template>
  <div class="quotes">
    <transition name="fade">
      <MDBContainer v-if="ready" class="mt-3 page-container">
        <Pageheader :title="`Quotes`" />
        <fieldset :disabled="busy">
          <MDBSpinner v-if="busy" />
          <MDBRow>
            <MDBCol class="" col="12" xl="8" lg="8"></MDBCol>
            <MDBCol class="d-flex justify-content-end mb-1 align-items-center" col="12" xl="4" lg="4">
              <MDBBtn tag="a" :href="`#/customers/${this.$route.params.customer_id}/quotes/create`" color="primary"
                size="sm" class="text-uppercase">New</MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBTable style="width: 100%;" align="middle" responsive sm striped variant="light" class="mt-3 shadow">
            <thead>
              <tr>
                <th>Quote</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(quote, i) in quotes" :key="quote.ID">
                <td>{{ quote.post_date }}</td>
                <td class="text-end " :data-quote="quote.ID">
                  <MDBDropdown v-model="tools[i]">
                    <MDBDropdownToggle @click="tools[i] = !tools[i]" size="sm"></MDBDropdownToggle>
                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                      <MDBDropdownItem href="#" @click.prevent="confirmDownload = true; createPDF($event);">
                        <MDBIcon icon="file-pdf" iconStyle="fas" /> Download
                      </MDBDropdownItem>
                      <MDBDropdownItem :href="`#/customers/${this.$route.params.customer_id}/quotes/${quote.ID}/edit`">
                        <MDBIcon icon="pencil-alt" iconStyle="fas" /> Edit
                      </MDBDropdownItem>
                      <MDBDropdownItem href="#" @click.prevent="selected = quote; confirmDelete = true"
                        class="text-danger">
                        <MDBIcon icon="trash-alt" iconStyle="fas" /> Delete
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </td>
              </tr>
            </tbody>
          </MDBTable>
          <Pagination :pagination="pagination" />
        </fieldset>
      </MDBContainer>
    </transition>
    <MDBModal size="sm" id="confirmDelete" tabindex="-1" labelledby="confirmDeleteLabel" v-model="confirmDelete"
      :staticBackdrop="busy">
      <MDBModalHeader>
        <MDBModalTitle id="confirmDeleteLabel">Confirm Delete</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>Are you sure you want to delete: {{ selected.post_title }}?</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn :disabled="busy" color="link" class="text-dark" size="md" @click="confirmDelete = false; selected = {}">
          Close</MDBBtn>
        <MDBBtn :disabled="busy" color="danger" size="md" @click="remove">Delete</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <MDBModal size="sm" id="confirmDownload" tabindex="-1" labelledby="confirmDownloadLabel" v-model="confirmDownload"
      :staticBackdrop="busy">
      <MDBModalHeader>
        <MDBModalTitle id="confirmDownloadLabel">Download Quote</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody class="text-center">
        <div v-if="downloadFile">
          <MDBBtn tag="a" :disabled="busy" color="primary" size="md" :href="downloadFile" :download="downloadFileName">
            Download</MDBBtn>
          <!-- <MDBBtn :disabled="busy" color="primary" size="md"  @click="sendEmail">E-Mail</MDBBtn> -->
        </div>
        <div v-else>Creating Quote...</div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn :disabled="busy" color="link" class="text-dark" size="md"
          @click="confirmDownload = false; downloadFile = undefined; downloadFileName = undefined">Close</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'quotes',
  components: {
  },
  setup() {
    const confirmDelete = ref(false);
    const confirmDownload = ref(false);
    return {
      confirmDelete,
      confirmDownload,
    }
  },
  data() {
    return {
      quotes: [],
      selected: {},
      ready: false,
      count: 0,
      busy: false,
      searchText: '',
      downloadFile: undefined,
      downloadFileName: undefined,
      tools: [],
      pagination: {
        count: 0,
        page: 1,
        per_page: 10,
        pages: 1,
        searchText: ''
      },
    }
  },
  watch: {
    'pagination.count': async function () {
      this.pagination.page = 1;
    },
    pagination: {
      deep: true,
      async handler(newVal) {
        this.quotes = await this.$store.state.db.quotes.where('filter').equals(this.$route.params.customer_id).offset(((newVal.page - 1) * newVal.per_page)).limit(newVal.per_page).toArray();
        this.quotes.forEach((r, i) => {
          this.tools[i] = false;
          if (typeof r.acf == 'string') this.quotes[i].acf = JSON.parse(r.acf);
        })

        this.pagination.count = await this.$store.state.db.quotes.where('filter').equals(this.$route.params.customer_id).count();
        this.pagination.pages = Math.ceil(this.pagination.count / newVal.per_page);
        window.scrollTo(0, 0);
      }
    }
  },
  async mounted() {
    let c = (await this.$store.state.db.customers.where('ID').equals(this.$route.params.customer_id).toArray())[0];
    if (c.hasOwnProperty('c')) {
      if (typeof c.acf == 'string') c.acf = JSON.parse(c.acf);
    }
    else c.acf = {};
    this.pagination.count = await this.$store.state.db.quotes.count();
    this.ready = true;
  },
  methods: {
    async createPDF($event) {
      // this.createExcel($event);
      let obj = { ID: ($event.target.closest('[data-quote]').getAttribute('data-quote')) };
      obj = _.first(await this.$store.state.db.quotes.where({ 'ID': obj.ID }).toArray());
      if (typeof obj.acf === 'string') obj.acf = JSON.parse(obj.acf);
      this.downloadFileName = obj.acf.bpc_no + '.pdf';

      this.$store.state.pdfWorker = new Worker(`./js/PDFWorker.js?v=${new Date().getTime()}`);

      this.$store.state.pdfWorker.addEventListener('message', async e => {
        this.downloadFile = e.data.b64;
        if (this.$store.state.pdfWorker.hasOwnProperty('terminate')) this.$store.state.pdfWorker.terminate();

      });
      this.$store.state.pdfWorker.postMessage({ user: this.$store.state.user.ID, ID: ($event.target.closest('[data-quote]').getAttribute('data-quote')) });

    },

    async remove() {
      this.busy = true;
      this.confirmDelete = ref(false);
      await this.$store.state.db.quotes.delete(this.selected.id);
      this.$router.replace({ query: { success: `${btoa('Quote successfully deleted')}` } });
      this.pagination = {
        count: 0,
        page: 1,
        per_page: 10,
        pages: 1,
        searchText: ''
      };
      this.busy = false;
    }
  }
}
</script>
<style lang="scss"></style>