<template>
  <div class="regions-create">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="`Create Region`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Gallons</label>
                    <Field type="number"  class="form-control" name="gallons" v-model="gallon.acf['gallons']"  />
                    <ErrorMessage class="text-danger" name="gallons" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">8000</label>
                    <Field type="number" class="form-control" name="8000" v-model="gallon.acf['8000']"  />
                    <ErrorMessage class="text-danger" name="8000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">6000</label>
                    <Field type="number" class="form-control" name="6000" v-model="gallon.acf['6000']"  />
                    <ErrorMessage class="text-danger" name="6000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">4000</label>
                    <Field type="number" class="form-control" name="4000" v-model="gallon.acf['4000']"  />
                    <ErrorMessage class="text-danger" name="4000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">2000</label>
                    <Field type="number" class="form-control" name="2000" v-model="gallon.acf['2000']"  />
                    <ErrorMessage class="text-danger" name="2000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">500 or less</label>
                    <Field type="number" class="form-control" name="500_or_less" v-model="gallon.acf['500_or_less']"  />
                    <ErrorMessage class="text-danger" name="500_or_less" />
                </div>
                <PageCreateButtons :errors="errors" />
              </fieldset>
            </Form>
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import * as yup from 'yup';
export default {
  name: 'GallonsCreate',
  components: {
  },
  watch:{
  },
  data(){
    return {
      gallon: _.merge(..._.map(this.$store.state.db.gallons.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
        'gallons':yup.string().required('Field is required').label('Gallon'),
        '8000':yup.string().required('Field is required').label('8000'),
        '6000':yup.string().required('Field is required').label('6000'),
        '4000':yup.string().required('Field is required').label('4000'),
        '2000':yup.string().required('Field is required').label('2000'),
        '500_or_less':yup.string().required('Field is required').label('500_or_less')
      })
    }
  },
  async mounted(){
    this.gallon.acf = {gallons:'0','8000':'0.00','6000':'0.00','4000':'0.00','2000':'0.00','500_or_less':'0.00'};
    this.ready = true;
  },
  methods:{
    async create(){
        this.gallon.acf['8000'] = parseFloat(this.gallon.acf['8000'].replace(/\$+/gi, ``)).toFixed(2).toString();
        this.gallon.acf['6000'] = parseFloat(this.gallon.acf['6000'].replace(/\$+/gi, ``)).toFixed(2).toString();
        this.gallon.acf['4000'] = parseFloat(this.gallon.acf['4000'].replace(/\$+/gi, ``)).toFixed(2).toString();
        this.gallon.acf['2000'] = parseFloat(this.gallon.acf['2000'].replace(/\$+/gi, ``)).toFixed(2).toString();
        this.gallon.acf['500_or_less'] = parseFloat(this.gallon.acf['500_or_less'].replace(/\$+/gi, ``)).toFixed(2).toString();
        this.gallon.acf.gallons = parseFloat(this.gallon.acf.gallons).toString();
        this.gallon.post_title = this.gallon.acf.gallons;
        this.gallon.post_type = 'gallons';
        this.gallon.post_status = 'publish';
        this.busy = true;
        let req = await fetch(`${this.$store.state.api}/gallons/create`, {
          method:'POST',
          body:JSON.stringify(this.gallon),
          headers:{
              'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
          }
        });
        let res = await req.json();
        if(res.hasOwnProperty('error')){
          this.$router.replace({query:{danger:btoa(res.error)}});
        }
        if(res.hasOwnProperty('success')){
          await this.$store.state.db.gallons.add(res.gallon);
          this.$router.replace(`/gallons/${res.gallon.ID}/edit?success=${btoa(res.success)}`);
        }
        this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>