<template>
  <div class="territories-create">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="`Create Territory`" />
            <Form :validation-schema="schema" @submit="create" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Address</label>
                    <Field  class="form-control" name="address" v-model="territory.acf['address']"  />
                    <ErrorMessage class="text-danger" name="address" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">City</label>
                    <Field  class="form-control" name="city" v-model="territory.acf['city']"  />
                    <ErrorMessage class="text-danger" name="city" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">State</label>
                    <Field  class="form-control" name="state" v-model="territory.acf['state']" as="select">
                        <option value="AL">Alabama</option><option value="AK">Alaska</option><option value="AZ">Arizona</option><option value="AR">Arkansas</option><option value="CA">California</option><option value="CO">Colorado</option><option value="CT">Connecticut</option><option value="DE">Delaware</option><option value="DC">District Of Columbia</option><option value="FL">Florida</option><option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option><option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option><option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option><option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option><option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option><option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option><option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option><option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option><option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option><option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option><option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option><option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option><option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option><option value="WI">Wisconsin</option><option value="WY">Wyoming</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="state" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">ZIP</label>
                    <Field  class="form-control" name="zip" v-model="territory.acf['zip']"  />
                    <ErrorMessage class="text-danger" name="zip" />
                </div>
                <hr>
                <h4>Territory Numbers</h4>
                <table class="table table-sm table-borderless">
                  <tbody>
                    <tr v-for="(t,idx) in territory.acf.managers" :key="idx">
                      <td  class="ps-1 pe-1" style="vertical-align:top;">
                        <Field type="number" class="form-control" :name="`territories[${idx}].manager_no`" v-model="t.manager_number" placeholder="Territory Number" />
                        <ErrorMessage class="text-danger" :name="`territories[${idx}].manager_no`" />
                      </td>
                      <td class="ps-1 pe-1 text-center" style="vertical-align:middle;"><MDBIcon @click="territory.acf.managers.splice(idx, 1)" icon="trash-alt" iconStyle="fas" class="text-danger" /></td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-end">
                  <MDBBtn tag="a" @click="territory.acf.managers.push({manager_name:'',manager_number:'',manager_phone:''});" color="link" class="text-dark" size="md">Add Territory</MDBBtn>
                </div>
                <PageCreateButtons :errors="errors" />
              </fieldset>
              
            </Form>
            
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import * as yup from 'yup';
export default {
  name: 'TerrritoriesCreate',
  components: {
  },
  data(){
    return {
      territory: _.merge(..._.map(this.$store.state.db.territories.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
        'address':yup.string().required('Field is required').label('Address'),
        'city':yup.string().required('Field is required').label('City'),
        'state':yup.string().required('Field is required').label('State'),
        'zip':yup.string().required('Fiield is required'),
        'territories':yup.array().of(yup.object().shape({
          manager_no:yup.string().required().label('Territory number')
        })),
      })
    }
  },
  async mounted(){
    this.territory.acf = {address:'',city:'',state:'',zip:'',managers:[]};
    // this.territory = _.first(await this.$store.state.db.territories.where('ID').equals(parseInt(this.$route.params.territory_id)).toArray());
    // if(typeof this.territory.acf === 'string') this.territory.acf = JSON.parse(this.territory.acf); 
    this.ready = true;
  },
  methods:{
    async create(){
      // this.busy = true;
      delete this.territory.ID;
      this.territory.post_title = this.territory.acf.city.trim();
      this.territory.post_type = 'territories';
      this.territory.post_status='publish';
      let req = await fetch(`${this.$store.state.api}/territories/create`,{
        method:'POST',
        body:JSON.stringify(this.territory),
        headers:{
          'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });

      let  res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        // await this.$store.state.db.regions.add(res.region);
        // this.$router.replace({query:{success:btoa(res.success)}});
        await this.$store.state.db.territories.add(res.territory);
        this.$router.replace(`/territories/${res.territory.ID}/edit?success=${btoa(res.success)}`);
        // this.$router.replace({query:{success:btoa(res.success)}});
        // await this.$store.state.db.territories.where('ID').equals(this.territory.ID).modify({acf:JSON.stringify(this.territory.acf)});
        // await this.$store.state.db.territories.where('ID').equals(this.territory.ID).modify({post_title:(this.territory.post_title)});
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  select{
  
  }
</style>