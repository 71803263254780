<template>
  <div class="dryers-edit">
      <transition name="fade">
        <MDBContainer v-if="ready" class="mt-3 page-container">
          <Pageheader :title="dryer.post_title" />
            <Form :validation-schema="schema" @submit="update" v-slot="{ errors }" autocomplete="off">
              <fieldset :disabled="busy">
                <MDBSpinner v-if="busy" />
                <div class="form-group mt-2 mb-2">
                    <label for="">Dryer</label>
                    <Field  class="form-control" name="post_title" v-model="dryer['post_title']" readonly  />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">2 Visits</label>
                    <Field  type="number" class="form-control" name="4000" v-model="dryer.acf['4000']"  />
                    <ErrorMessage class="text-danger" name="4000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">3 Visits</label>
                    <Field type="number" class="form-control" name="6000" v-model="dryer.acf['6000']"  />
                    <ErrorMessage class="text-danger" name="6000" />
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="">4 Visits</label>
                    <Field type="number" class="form-control" name="8000" v-model="dryer.acf['8000']"  />
                    <ErrorMessage class="text-danger" name="8000" />
                </div>
                <PageUpdateButtons :errors="errors" />
              </fieldset>
              
            </Form>
            
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import * as yup from 'yup';
import {
    MDBListGroup, MDBListGroupItem,
    MDBProgress,
    MDBProgressBar,
    MDBContainer,
    MDBBtn,
    MDBSpinner
} from "mdb-vue-ui-kit";
export default {
  name: 'dryersEdit',
  components: {
    MDBListGroup,
    MDBListGroupItem,
    MDBProgress,
    MDBProgressBar,
    MDBContainer,
    MDBBtn,
    MDBSpinner,
    Field,
    Form,
    ErrorMessage,
    defineRule
  },
  data(){
    return {
      dryer: _.merge(..._.map(this.$store.state.db.dryers.schema.idxByName, (val,key)=>{ return {[key]:''} })),
      ready:false,
      busy:false,
      schema:yup.object().shape({
        '4000':yup.string().matches(/\d+/).required('Must be a valid number'),
        '6000':yup.string().matches(/\d+/).required('Must be a valid number'),
        '8000':yup.string().matches(/\d+/).required('Must be a valid number'),
      })
    }
  },
  async mounted(){
    this.dryer =  _.first(await this.$store.state.db.dryers.where('ID').equals(parseFloat(this.$route.params.dryer_id)).toArray());
    if(typeof this.dryer.acf === 'string') this.dryer.acf = JSON.parse(this.dryer.acf); 
    this.ready = true;
  },
  methods:{
    async update(){
      this.busy = true;
      let req = await fetch(`${this.$store.state.api}/dryers/${this.dryer.ID}`, {
        method:'PUT',
        body:JSON.stringify(this.dryer.acf),
        headers:{
            'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();
      if(res.hasOwnProperty('error')){
        this.$router.replace({query:{danger:btoa(res.error)}});
      }
      if(res.hasOwnProperty('success')){
        this.$router.replace({query:{success:btoa(res.success)}});
        await this.$store.state.db.dryers.where('id').equals(this.dryer.ID).modify({acf:JSON.stringify(this.dryer.acf)});
      }
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>