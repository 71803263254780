<template>
  <div class="regions-edit-all">
      <transition name="fade">
        <MDBContainer v-if="ready" style="max-width: 720px;" class="mt-3">
            <Pageheader :title="`Edit All Regions`" />
            <Form @submit="update" autocomplete="off">
                <fieldset :disabled="busy">
                    <MDBSpinner v-if="busy" />
                    <MDBTable style="width: 100%;" align="middle" responsive sm  variant="light" class="mt-3 shadow" v-if="$store.state.user.roles.indexOf('administrator')!=-1">
                        <thead>
                            <tr>
                            <th width="20"></th>
                            <th>Region</th>
                            <th>2 Visits</th>
                            <th>3 Visits</th>
                            <th>4 Visits</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(region,i) in regions" :key="region.ID">
                                <td  @click="removeRow(i)" class="p-1 text-center bg-danger text-white"><MDBIcon icon="minus-circle" iconStyle="fas" /></td>
                                <td class="p-1"><Field type="text" class="form-control rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][acf][region]`" v-model="region.acf['region']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][acf][2_visits]`" v-model="region.acf['2_visits']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][acf][3_visits]`" v-model="region.acf['3_visits']"  /></td>
                                <td class="p-1"><Field type="number" class="form-control  rounded-0 p-0 ps-1 pe-1" :name="`region[${i}][acf][4_visits]`" v-model="region.acf['4_visits']"  /></td>
                            </tr>
                        </tbody>
                    </MDBTable>
                    <PageUpdateButtons />
                </fieldset>
                
            </Form>
        </MDBContainer>
      </transition>
  </div>
</template>

<script>

import * as yup from 'yup';
export default {
  name: 'RegionsEditAll',
  components: {
  },
  data(){
    return {
      regions: [],
      ready:false,
      busy:false,
      schema:yup.object().shape({
        '2_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '3_visits':yup.string().matches(/\d+/).required('Must be a valid number'),
        '4_visits':yup.string().matches(/\d+/).required('Must be a valid number')
      })
    }
  },
  async mounted(){
    this.regions =  (await this.$store.state.db.regions.orderBy('post_title').toArray());
    this.regions = _.map(this.regions, region=>{
        if(typeof region.acf === 'string') region.acf = JSON.parse(region.acf);
        return region; 
    })
    this.ready = true;
  },
  methods:{
    async removeRow(index){
      console.log(index)
      this.regions.splice(index,1)
    },
    async update(){
      this.busy = true;
    //   let req = await fetch(`${this.$store.state.api}/regions/${this.region.ID}?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.region.acf), p=>p.join('=')).join('&'))))}`, {
    //     method:'PUT',
    //     body:JSON.stringify(this.region.acf),
    //     headers:{
    //         'Authorization':`Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
    //     }
    //   });
    //   let res = await req.json();
    //   if(res.hasOwnProperty('error')){
    //     this.$router.replace({query:{danger:btoa(res.error)}});
    //   }
    //   if(res.hasOwnProperty('success')){
    //     this.$router.replace({query:{success:btoa(res.success)}});
    //     await this.$store.state.db.regions.where('id').equals(this.region.ID).modify({acf:JSON.stringify(this.region.acf)});
    //   }
      await Sleep(2000);
      this.busy = false;
    }
  },
}
</script>
<style lang="scss">
  
</style>