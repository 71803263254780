<template>
  <div class="customers">
    <transition name="fade">
      <MDBContainer v-if="ready" class="mt-3 page-container">
        <Pageheader :title="`Customers`" />
        <fieldset :disabled="busy">
          <MDBSpinner v-if="busy" />
          <MDBRow>
            <MDBCol class="" col="12" xl="8" lg="8"><input v-model="pagination.searchText" class="form-control"
                placeholder="Search"></MDBCol>
            <MDBCol class="d-flex justify-content-end mb-1 align-items-center mt-2 mt-lg-0" col="12" xl="4" lg="4">
              <MDBDropdown v-model="exportFile">
                <MDBDropdownToggle @click="exportFile = !exportFile" size="sm">CSV</MDBDropdownToggle>
                <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                  <!-- <MDBDropdownItem href="#" @click.prevent="exportCSV">Export</MDBDropdownItem> -->
                  <MDBDropdownItem href="#" @click.prevent="importFile = true">Import</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBBtn tag="a" :href="`#/customers/create`" color="primary" size="sm" class="text-uppercase">New</MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBTable style="width: 100%;" align="middle" responsive sm striped variant="light" class="mt-3 shadow">
            <thead>
              <tr>
                <th>Customer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(customer, i) in customers" :key="customer.ID">
                <td><b><u>{{ customer.customer_name }}:</u></b> {{ customer.acf.address_1 }}</td>
                <td class="text-end">
                  <MDBDropdown v-model="tools[i]">
                    <MDBDropdownToggle @click="tools[i] = !tools[i]" size="sm"></MDBDropdownToggle>
                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                      <MDBDropdownItem :href="`#/customers/${customer.ID}/quotes`">
                        <MDBIcon icon="file-pdf" iconStyle="fas" /> Quotes
                      </MDBDropdownItem>
                      <!-- <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator')!=-1" :href="`#/customers/${customer.ID}/edit`"><MDBIcon icon="pencil-alt" iconStyle="fas" /> Edit</MDBDropdownItem> -->
                      <!-- <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator')!=-1" href="#" @click.prevent="selected=customer; confirmDelete = true" class="text-danger"><MDBIcon icon="trash-alt" iconStyle="fas" /> Delete</MDBDropdownItem>  -->
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </td>
              </tr>
            </tbody>
          </MDBTable>
          <Pagination :pagination="pagination" />
        </fieldset>
      </MDBContainer>
    </transition>
    <MDBModal size="sm" id="confirmDelete" tabindex="-1" labelledby="confirmDeleteLabel" v-model="confirmDelete"
      :staticBackdrop="busy">
      <MDBModalHeader>
        <MDBModalTitle id="confirmDeleteLabel">Confirm Delete</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>Are you sure you want to delete: {{ selected.customer_name }}?</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn :disabled="busy" color="link" class="text-dark" size="md" @click="confirmDelete = false; selected = {}">
          Close</MDBBtn>
        <MDBBtn :disabled="busy" color="danger" size="md" @click="remove">Delete</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <MDBModal size="lg" id="importFile" tabindex="-1" labelledby="importFileLabel" v-model="importFile"
      :staticBackdrop="busy">
      <MDBModalHeader>
        <MDBModalTitle id="importFileLabel">Import CSV</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <MDBFile v-model="file" label="Upload CSV" accept=".csv" />
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn :disabled="busy" color="link" class="text-dark" size="md" @click="importFile = false; file = null">Close
        </MDBBtn>
        <MDBBtn :disabled="busy" color="danger" size="md" @click="importCSV">Upload</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </div>
</template>

<script>

import { ref } from 'vue';
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBCol, MDBRow, MDBTable,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter, MDBSpinner
} from "mdb-vue-ui-kit";
export default {
  name: 'Customers',
  components: {
    MDBContainer,
    MDBBtn,
    MDBIcon,
    MDBCol, MDBRow, MDBTable,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter, MDBSpinner,

  },
  setup() {
    const confirmDelete = ref(false);
    const exportFile = ref(false);
    const importFile = ref(false);
    const addingCustomers = ref(false);
    return {
      confirmDelete, exportFile, importFile, addingCustomers
    }
  },
  data() {
    return {
      customers: [],
      selected: {},
      ready: false,
      adding: 0,
      count: 0,
      busy: false,
      searchText: '',
      file: null,
      tools: [],
      pagination: {
        count: 0,
        page: 1,
        per_page: 10,
        pages: 1,
        searchText: ''
      },
    }
  },
  watch: {
    'pagination.count': async function () {
      this.pagination.page = 1;
    },
    pagination: {
      deep: true,
      async handler(newVal) {
        this.customers = await this.$store.state.db.customers.where('customer_name').startsWithIgnoreCase(this.pagination.searchText.trim()).offset(((newVal.page - 1) * newVal.per_page)).limit(newVal.per_page).toArray();
        this.customers.forEach((r, i) => {
          this.tools[i] = false;
          if (typeof r.acf == 'string') this.customers[i].acf = JSON.parse(r.acf);
        })
        this.pagination.count = await this.$store.state.db.customers.where('customer_name').startsWithIgnoreCase(this.pagination.searchText.trim()).count();
        this.pagination.pages = Math.ceil(this.pagination.count / newVal.per_page);
        window.scrollTo(0, 0);
      }
    }
  },
  async mounted() {
    this.pagination.count = await this.$store.state.db.customers.count();
    this.ready = true;
  },
  methods: {
    async importCSV() {
      this.busy = true;
      this.importFile = false;
      this.$root.downloadModal = true;
      this.$root.progress = {
        start: 0,
        end: this.$store.state.schema['customers'].max_num_pages,
        progress: 0
      };
      let url = await this.upload();
      await this.$store.state.db.customers.clear();
      let req = await fetch(`${url}?v=${new Date().getTime()}`);
      let res = await req.text();
      this.count = Papa.parse(res).data.length;
      let i = 0;
      let customers = [];
      let data = Papa.parse(res, {
        worker: true,
        step: async (results) => {
          results.data = {
            customer_name: results.data[4],
            post_modified_gmt: '',
            ID: results.data[0],
            post_date: '',
            post_date_gmt: '',
            post_title: '',
            post_type: '',
            post_modified: '',
            acf: JSON.stringify({
              customer_id: results.data[0],
              account_number: results.data[3],
              customer_name: results.data[4],
              address_1: results.data[5],
              address_2: '',
              address_3: '',
              address_4: '',
              city: results.data[6],
              state: results.data[7],
              zip: results.data[8],
              county: '',
              contact: results.data[12],
              phone: results.data[13],
              email: results.data[14],
              locations: {
                bill_to: {
                  address: results.data[5],
                  city: results.data[6],
                  state: results.data[7],
                  zip: results.data[8],
                },
                ship_to: {
                  address: results.data[9],
                  city: results.data[10],
                  zip: results.data[11],
                }
              }
            })
          };
          customers.push(results.data);
          // await this.$store.state.db.customers.add(results.data);
        },
        complete: async () => {
          customers = _.chunk(customers, 500);
          let from = 0;
          let to = 0;
          for (let i = 0; i < customers.length; i++) {
            to += customers[i].length;
            from = to - customers[i].length;
            this.$root.message = `Adding Customers ${from} - ${to}`;
            await Sleep(100);
            await this.$store.state.db.customers.bulkAdd(customers[i]);
          }
          console.log(customers);
          this.adding = 0;
          this.pagination.page = 0;
          this.pagination = {
            count: 0,
            page: 1,
            per_page: 10,
            pages: 1,
            searchText: ''
          };
          await Sleep(1000);
          this.$root.downloadModal = false;
          this.busy = false;
        }
      });

      // console.log(data);

      // console.log(JSON.stringify(data.data[1]))

      // await this.$root.getSchema();
      // await this.$root.getTable('customers');
      // this.pagination = {
      //     count:0,
      //     page:1,
      //     per_page:10,
      //     pages:1,
      //     searchText:''
      // };
      // await Sleep(1000);
      // this.$root.downloadModal = false;
      // this.busy = false;
    },
    async upload() {
      return new Promise(async resolve => {
        this.$root.message = `Uploading CSV File`;
        let formData = new FormData();
        formData.append('file', this.file[0]);
        let req = await fetch(`${this.$store.state.api}/customers?hash=${this.$store.state.application_password}`, {
          method: 'POST',
          body: formData,
        });
        let res = await req.json();
        // _.each(res.remove_customers, async region=>{
        //   await this.$store.state.db.customers.where("customer_id").equals((region)).delete();
        // })
        resolve(res);
      });
    },
    async remove() {
      this.busy = true;
      let req = await fetch(`${this.$store.state.api}/customers/${this.selected.ID}?hash=${this.$store.state.application_password}${sha256(btoa(rawurlencode(_.map(_.toPairs(this.selected.acf), p => p.join('=')).join('&'))))}`, {
        method: 'DELETE',
        body: JSON.stringify(this.selected.acf),
        headers: {
          'Authorization': `Basic ${btoa(`${this.$store.state.user.data.user_email}:${this.$store.state.user.data.user_pass}`)}`
        }
      });
      let res = await req.json();

      if (res.hasOwnProperty('error')) {
        this.$router.push({ query: { danger: btoa(res.error) } });
      }
      if (res.hasOwnProperty('success')) {
        this.confirmDelete = ref(false);
        await this.$store.state.db.customers.delete(this.selected.id);
        this.$router.push({ query: { success: `${btoa(res.success)}` } });

        this.pagination = {
          count: 0,
          page: 1,
          per_page: 10,
          pages: 1,
          searchText: ''
        };

      }
      this.busy = false;
    }
  }
}
</script>
<style lang="scss"></style>